import React, { useEffect, useState } from "react";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";

import {
  ImsInvoice,
  PurchaseOrder,
  Vendor,
} from "../../atomic_components/types";
import { Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import Datetime from "react-datetime";
import DatePicker from "react-modern-calendar-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./CSS/InvoicesCreate.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";

import { set } from "lodash";
import CustomDateTimePicker from "./CustomDatePicker";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import DateTimeUtils from "../../utils/DateTimeUtils";
import InvoiceNetworkUtils from "./NetworkUtils/InvoiceNetworkUtils";
import { useHistory, useLocation } from "react-router-dom";
import CustomDatePicker from "./CustomDatePicker";

function InvoicesCreate() {
  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const invoiceNetworkUtil = new InvoiceNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  useEffect(() => {
    fetchAllVendors();
  }, []);

  const [vendorsList, setVendorsList] = useState<Vendor[]>([]);

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const fetchAllVendors = async () => {
    try {
      const response = await vendorsNetworkUtil.getAllVendors();
      setVendorsList(response.data);
    } catch (error) {
      console.error("Error in fetching vendor listings by vendor id :", error);
    }
  };

  const [invoice, setInvoice] = useState<ImsInvoice>({
    id: 0,
    customer_name: "",
    customer_contact_number: "",
    customer_billing_address: "",
    customer_shipping_address: "",
    customer_gstin: "",
    invoice_status: "",
    invoice_date: "",
    payment_mode: "CASH",
    created_by: "",
    date_published: "",
    created_at: "",
    updated_at: "",
    total_quantity: 0,
    item_count: 0,
  });

  // Event handler to update the state when the input value changes

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInvoice({
      ...invoice,
      [name]: value,
    });
  };

  const handleDropDownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    console.log(name, value);
    setInvoice({
      ...invoice,
      [name]: value,
    });
  };

  const [providedSelectedValue, setProvidedSelectedValue] =
    useState<string>("");

  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);

  const handleDateTimeChange = (newDateTime: Date | null) => {
    setSelectedDateTime(newDateTime);
  };

  const createInvoiceAndProceed = async () => {
    // history.push(`/purchase-orders/add/addItems`);
    let error_count = 0;
    if (!selectedDateTime) {
      toast.error("Invoice date not selected", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (!invoice.customer_name || invoice.customer_name.length < 5) {
      toast.error("Valid customer name is required", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (
      !invoice.customer_contact_number ||
      invoice.customer_contact_number.length < 10
    ) {
      toast.error(
        "Invalid customer number provided, It must have at least 10 digits.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
      error_count++;
    }

    if (
      !invoice.customer_billing_address ||
      invoice.customer_billing_address.length < 10
    ) {
      toast.error("Provide a valid billing address.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }
    if (
      !invoice.customer_shipping_address ||
      invoice.customer_shipping_address.length < 10
    ) {
      toast.error("Provide a valid shipping address.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (!invoice.payment_mode) {
      toast.error("Select an appropriate payment_mode.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (error_count > 0) return;
    setShowLoadingPage(true);
    const body = {
      customer_name: invoice.customer_name,
      customer_contact_number: invoice.customer_contact_number,
      customer_billing_address: invoice.customer_billing_address,
      customer_shipping_address: invoice.customer_shipping_address,
      customer_gstin: invoice.customer_gstin,
      payment_mode: invoice.payment_mode,
      invoice_status: "PENDING",
      invoice_date: selectedDateTime
        ? DateTimeUtils.formatDateTimeForDb(selectedDateTime)
        : "",
      created_by: userDetailsFromStore.user_details.userId,
    };
    try {
      const response = await invoiceNetworkUtil.createNewInvoice(body);
      const createdInvoice: ImsInvoice = response.data;
      setShowLoadingPage(false);
      history.push(`/invoices/add/addItems/${createdInvoice.id}`);
    } catch (error) {
      console.error("Error in creating invoice :", error);
    }
  };

  return (
    <div className="invoice-container mt-4">
      <div className="invoice-form-container">
        <div className="invoice-main-form">
          <div className="dropdown-container mb-2">
            {/* <VendorNamesDropdown
            options={vendorsList}
            onItemSelected={handleVendorFromDropdownSelected}
            providedSelectedValue={providedSelectedValue}
          /> */}
          </div>

          <FloatingLabel
            controlId="floatingInput"
            label="Customer Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="customer_name"
              value={invoice.customer_name}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Customer Contact Number"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="customer_contact_number"
              value={invoice.customer_contact_number}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Customer Billing Address"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="customer_billing_address"
              value={invoice.customer_billing_address}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Customer Shipping Address"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="customer_shipping_address"
              value={invoice.customer_shipping_address}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Customer GSTIN"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="customer_gstin"
              value={invoice.customer_gstin}
              onChange={handleChange}
            />
          </FloatingLabel>

          <div className="date-time-picker-container-main">
            <div className="date-time-picker-selected-date-container ml-1 mb-1">
              <span>Select Payment Mode: </span> &nbsp;
            </div>
            <div className="date-time-picker-container">
              <select
                id="payment"
                value={invoice.payment_mode}
                name="payment_mode"
                className="date-time-picker-select"
                onChange={handleDropDownChange}
              >
                <option value="CASH">CASH</option>
                <option value="CARD">CARD</option>
                <option value="Net Banking">Net Banking</option>
                <option value="Cheques">Cheques</option>
                <option value="UPI">UPI</option>
              </select>
            </div>
          </div>

          <div>
            <CustomDatePicker onDateTimeChange={handleDateTimeChange} />
          </div>
          <div className="purchase-order-create-button-container mt-4">
            <Button
              className="purchase-order-create-button"
              variant="outline-primary"
              onClick={createInvoiceAndProceed}
            >
              Submit
            </Button>
          </div>
          {/* <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        
        placeholderText="Select a date"
      /> */}
        </div>
      </div>

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Creating invoice, please wait...
        </div>
      )}
    </div>
  );
}

export default InvoicesCreate;
