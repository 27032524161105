import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import {
  ImsInvoice,
  ImsInvoiceItem,
  PoItem,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemWithLisiting,
  Vendor,
} from "../../atomic_components/types";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { requestHeaders } from "../../NetworkUtils/Requests";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  itemPage: {
    position: "relative",
    width: "100%",
    height: "100%",
    // backgroundImage: 'url("/catalogue/bg1.png")',
    // backgroundSize: "cover", // Adjust this property based on your needs
    // backgroundPosition: "center", // Adjust this property based on your needs
    // opacity: 0.5,
  },
  imageContainer: {
    width: "30%", // Adjust the width as needed
    marginRight: 10,
    borderRight: "1px solid #ccc",
  },
  detailsContainer: {
    width: "70%", // Adjust the width as needed
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 20,
  },
  productImage: {
    width: "80%", // Adjust the image width as needed
    height: "auto",
  },
  poHeaderText: {
    fontSize: 22, // Adjust the font size for the Purchase Order text
    fontWeight: "ultrabold",
    marginTop: 15,
  },
  poIdText: {
    fontSize: 16, // Adjust the font size for the Purchase Order text
    fontWeight: "ultrabold",
    marginTop: 4,
    marginBottom: 8,
  },
  poDate: {},
  poItemText: {
    fontSize: 12, // Adjust the font size for item details
    marginBottom: 3,
  },
  requiredQuantity: {
    fontSize: 14, // Adjust the font size for item details
    marginBottom: 8,
    marginTop: 10,
  },
  itemContainer: {
    flexDirection: "row",
    margin: 5,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    border: "1px solid #ccc",
  },
  logoContainer: {
    alignItems: "flex-start",
    width: "48%",
    fontWeight: "normal",
    padding: 10,
  },
  invoiceDetailsContainer: {
    alignItems: "flex-start",
    width: "48%",
    fontWeight: "normal",
    padding: 10,
  },
  invoiceDetailsContainer1: {
    alignItems: "flex-start",
    width: "48%",
    fontWeight: "normal",
    borderRight: "1px solid black",
    padding: 10,
  },
  grandTotal: {
    alignItems: "flex-end",
    width: "100%",
    fontWeight: "normal",
    padding: 10,
  },
  srNo: {
    alignItems: "flex-start",
    width: "5%",
    fontWeight: "normal",
    padding: 10,
    borderRight: "1px solid black",
  },
  itemAndDescription: {
    alignItems: "flex-start",
    width: "47%",
    fontWeight: "normal",
    borderRight: "1px solid black",
    padding: 10,
  },
  qty: {
    alignItems: "flex-start",
    width: "8%",
    fontWeight: "normal",
    borderRight: "1px solid black",
    padding: 10,
  },
  unitPrice: {
    alignItems: "flex-start",
    width: "10%",
    fontWeight: "normal",
    borderRight: "1px solid black",
    padding: 10,
  },
  taxAmount: {
    alignItems: "flex-start",
    width: "10%",
    fontWeight: "normal",
    borderRight: "1px solid black",
    padding: 10,
  },
  amount: {
    alignItems: "flex-start",
    width: "10%",
    fontWeight: "normal",
    padding: 10,
    borderRight: "1px solid black",
  },
  totalAmount: {
    alignItems: "flex-start",
    width: "10%",
    fontWeight: "normal",
    padding: 10,
  },
  logoImage: {
    height: 50, // Adjust the height as needed
  },
  containerAllBorder: {
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid black",
    fontSize: 10,
  },
  containerTopBorder: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    fontSize: 10,
  },
  containerBottomBorder: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    fontSize: 10,
  },
  deliveryTermsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 30,
  },
  addressContainerAddressAndSummary: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 40,
  },
  addressColumnParent: {
    width: "49%", // Adjust the width as needed
  },
  addressColumn: {
    border: "1px solid #ccc",
    borderRadius: 5,
    marginBottom: 10,
  },
  itemInfoRow: {
    marginBottom: 10,
    padding: 10,
    color: "black",
    fontWeight: "ultrabold",
    fontSize: 12,
  },

  itemInfoColumn: {
    marginBottom: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  poIdColumn: {
    width: "48%", // Adjust the width as needed
    marginBottom: 10,
    fontSize: 12,
    border: "1px solid #ccc",
  },
  addressColumnDetails: {
    padding: 10,
    fontSize: 12,
  },
  purchaseOrderTerms: {
    padding: 10,
    fontSize: 12,
  },
  addressTitle: {
    fontWeight: "ultrabold",
    marginBottom: 5,
  },
  invoiceNormalText: {
    fontSize: 10,
  },
  invoiceSmallText: {
    fontSize: 8,
  },
  addressText: {
    fontSize: 10,
  },
  labelAndDateContainer: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontSize: 12,
    padding: 10,
  },
  vendorName: {
    marginBottom: 5,
    fontWeight: "ultrabold",
  },
  purchaseOrderTermsText: {
    marginBottom: 5,
  },
  itemsContainer: {
    // position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 8,
  },
});

const InvoicesPDF: React.FC<{
  invoiceItems: ImsInvoiceItem[];
  invoice: ImsInvoice | null;
  totalItems: number;
  totalQuantity: number;
}> = ({ invoiceItems, invoice, totalItems, totalQuantity }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.containerTopBorder}>
          <View style={styles.logoContainer}>
            <Text style={styles.poIdText}>OceanJewels Pvt Ltd.</Text>
            <Text>343, Mahaveer Nagar, Tonk Road</Text>
            <Text>Jaipur, Rajasthan, 302044</Text>
            <Text>GST - 22AAAAA0000A1Z5</Text>
            <Text>+91 8824518824</Text>
          </View>
          <View style={styles.labelAndDateContainer}>
            <Image
              src="/ocean-jewels-complete-logo.PNG" // Update this with the path to your logo
              style={styles.logoImage}
            />
            <Text style={styles.poHeaderText}>INVOICE</Text>
          </View>
        </View>
        <View style={styles.containerTopBorder}>
          <View style={styles.invoiceDetailsContainer}>
            <Text>Invoice no. : {invoice ? invoice.id : ""}</Text>
            <Text>
              Invoice date :{" "}
              {invoice
                ? DateTimeUtils.formatDateOnly(invoice.date_published)
                : ""}
            </Text>
            <Text>Payment mode : {invoice ? invoice.payment_mode : ""}</Text>
          </View>
          <View style={styles.labelAndDateContainer}></View>
        </View>
        <View style={styles.containerTopBorder}>
          <View style={styles.invoiceDetailsContainer1}>
            <Text style={styles.addressTitle}>Bill To :</Text>
            {invoice && <Text>{invoice.customer_billing_address}</Text>}
            {invoice && <Text>GSTIN - {invoice.customer_gstin}</Text>}
          </View>
          <View style={styles.invoiceDetailsContainer}>
            <Text style={styles.addressTitle}>Ship To :</Text>
            {invoice && <Text>{invoice.customer_shipping_address}</Text>}
          </View>
        </View>
        <View style={styles.containerAllBorder}>
          <View style={styles.srNo}>
            <Text style={styles.addressTitle}>#</Text>
          </View>
          <View style={styles.itemAndDescription}>
            <Text style={styles.addressTitle}>Item & Description</Text>
          </View>
          <View style={styles.qty}>
            <Text style={styles.addressTitle}>Qty</Text>
          </View>
          <View style={styles.unitPrice}>
            <Text style={styles.addressTitle}>Unit Price</Text>
          </View>
          <View style={styles.amount}>
            <Text style={styles.addressTitle}>Taxable Amount</Text>
          </View>
          <View style={styles.taxAmount}>
            <Text style={styles.addressTitle}>Taxes</Text>
          </View>
          <View style={styles.totalAmount}>
            <Text style={styles.addressTitle}>Total Amount</Text>
          </View>
        </View>

        {invoiceItems.map((invoiceItem, index) => (
          <View style={styles.containerBottomBorder}>
            <View style={styles.srNo}>
              <Text style={styles.addressTitle}>{index + 1}</Text>
            </View>
            <View style={styles.itemAndDescription}>
              <Text style={styles.addressTitle}>{invoiceItem.item_id}</Text>
              <Text style={styles.addressTitle}>
                {invoiceItem.listing.item_description}
              </Text>
            </View>
            <View style={styles.qty}>
              <Text style={styles.addressTitle}>
                {invoiceItem.item_quantity}
              </Text>
            </View>
            <View style={styles.unitPrice}>
              <Text style={styles.addressTitle}>
                {invoiceItem.item_final_price}
              </Text>
            </View>
            <View style={styles.amount}>
              <Text style={styles.addressTitle}>
                {invoiceItem.item_final_price * invoiceItem.item_quantity}
              </Text>
            </View>
            <View style={styles.taxAmount}>
              <Text style={styles.invoiceNormalText}>
                {Math.round(
                  invoiceItem.item_final_price *
                    invoiceItem.item_quantity *
                    (invoiceItem.listing.gst / 100) *
                    100
                ) / 100}
              </Text>
              <Text style={styles.invoiceSmallText}>
                gst - {invoiceItem.listing.gst} %
              </Text>
            </View>
            <View style={styles.totalAmount}>
              <Text style={styles.addressTitle}>
                {Math.round(
                  invoiceItem.item_final_price *
                    invoiceItem.item_quantity *
                    (1 + invoiceItem.listing.gst / 100) *
                    100
                ) / 100}
              </Text>
            </View>
          </View>
        ))}
        <View style={styles.containerBottomBorder}>
          <View style={styles.grandTotal}>
            <Text style={styles.addressTitle}>
              Grand Total (INR) - Rs.{" "}
              {Math.round(
                invoiceItems.reduce(
                  (total, invoiceItem) =>
                    total +
                    Math.round(
                      invoiceItem.item_final_price *
                        invoiceItem.item_quantity *
                        (1 + invoiceItem.listing.gst / 100) *
                        100
                    ) /
                      100,
                  0
                ) * 100
              ) / 100}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);
export default InvoicesPDF;
