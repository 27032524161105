import React, { useEffect, useState } from "react";
import {
  ErrorMessageBody,
  ImsListing,
  ImsListingErrorMessageObject,
  Listing,
  PacksAndCombos,
  PurchaseOrder,
  Vendor,
  VendorAndCostPrice,
} from "../../atomic_components/types";
import "./CSS/ListingAddNewItem.css";
import Header from "../Header";
import ListingBinGrid from "./ListingBinGrid";
import ListingNetworkUtil from "./NetworkUtils/ListingNetworkUtils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state";
import {
  Listings__Action,
  TopListings__Action,
} from "../../state/action-creators";
import {
  ListingActionTypes,
  TopListingActionTypes,
} from "../../state/action-types";
import { round } from "@floating-ui/utils";
import { Badge, Button, Offcanvas, Spinner, Table } from "react-bootstrap";
import _ from "lodash";
import FilteredDropdown from "../Vendors/FilteredDropdown";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import DateTimeUtils from "../../utils/DateTimeUtils";

interface ListingAddNewItemProps {
  listing: Listing; // Ensure the prop is correctly defined
}

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
  sku: string;
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// export default function ListingDetails({ listing }: ListingDetailsProps) {
export default function ListingsAddNewItem() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const { category, sku } = useParams<RouteParams>();
  const [selectedImage, setSelectedImage] = useState("img_main");

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showLoadingPageForSubmission, setShowLoadingPageForSubmission] =
    useState<boolean>(false);
  const [uploadedImages, setUploadedImages] = useState<any>({
    img_main: "http://tech.intellozene.com/Media/na.png",
    img_other1: "http://tech.intellozene.com/Media/na.png",
    img_other2: "http://tech.intellozene.com/Media/na.png",
    img_other3: "http://tech.intellozene.com/Media/na.png",
    img_other4: "http://tech.intellozene.com/Media/na.png",
  });

  const [uploadedImageFiles, setUploadedImageFiles] = useState<any>({
    img_main: null, // Initially set to null or empty
    img_other1: null,
    img_other2: null,
    img_other3: null,
    img_other4: null,
  });

  const [submitButtonClicked, setSubmitButtonClicked] =
    useState<boolean>(false);

  const [localListing, setLocalListing] = useState<ImsListing>({
    id: 0,
    item_id: "",
    item_description: "",
    item_category: "",
    img_main: "",
    img_other1: "",
    img_other2: "",
    img_other3: "",
    img_other4: "",
    item_weight: 0,
    gst: 0,
    hsn: "",
    length: 0,
    width: 0,
    height: 0,
    item_price: 0,
    available_quantity: 0,
    created_at: "",
    updated_at: "",
  });

  const [listingErrorObject, setListingErrorObject] =
    useState<ImsListingErrorMessageObject>({
      id: "",
      item_id: "",
      item_description: "",
      item_category: "",
      img_main: "",
      img_other1: "",
      img_other2: "",
      img_other3: "",
      img_other4: "",
      item_weight: "",
      gst: "",
      hsn: "",
      length: "",
      width: "",
      height: "",
      item_price: "",
      available_quantity: "",
      created_at: "",
      updated_at: "",
    });

  const resetDataObjects = () => {
    setLocalListing({
      id: 0,
      item_id: "",
      item_description: "",
      item_category: "",
      img_main: "",
      img_other1: "",
      img_other2: "",
      img_other3: "",
      img_other4: "",
      item_weight: 0,
      gst: 0,
      hsn: "",
      length: 0,
      width: 0,
      height: 0,
      item_price: 0,
      available_quantity: 0,
      created_at: "",
      updated_at: "",
    });

    setListingErrorObject({
      id: "",
      item_id: "",
      item_description: "",
      item_category: "",
      img_main: "",
      img_other1: "",
      img_other2: "",
      img_other3: "",
      img_other4: "",
      item_weight: "",
      gst: "",
      hsn: "",
      length: "",
      width: "",
      height: "",
      item_price: "",
      available_quantity: "",
      created_at: "",
      updated_at: "",
    });

    setUploadedImages({
      img_main: "http://tech.intellozene.com/Media/na.png",
      img_other1: "http://tech.intellozene.com/Media/na.png",
      img_other2: "http://tech.intellozene.com/Media/na.png",
      img_other3: "http://tech.intellozene.com/Media/na.png",
      img_other4: "http://tech.intellozene.com/Media/na.png",
    });

    setUploadedImageFiles({
      img_main: null, // Initially set to null or empty
      img_other1: null,
      img_other2: null,
      img_other3: null,
      img_other4: null,
    });
  };

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  const handleImageError = (imageKey: string) => {
    const updatedListing = { ...localListing };
    updatedListing[imageKey] = "http://tech.intellozene.com/Media/na.png";
    setLocalListing(updatedListing);
  };

  const getClassForError = (error: string) => {
    if (submitButtonClicked && error.length > 0)
      return "grid-item-details-card error";
    else return "grid-item-details-card";
  };

  const getToolTipForError = (error: string) => {
    if (submitButtonClicked && error.length > 0) return error;
    else return "";
  };

  const checkForErrors = () => {
    let errorCount = 0;
    const localListingErrorObject: ImsListingErrorMessageObject = {
      id: "",
      item_id: "",
      item_description: "",
      item_category: "",
      img_main: "",
      img_other1: "",
      img_other2: "",
      img_other3: "",
      img_other4: "",
      item_weight: "",
      gst: "",
      hsn: "",
      length: "",
      width: "",
      height: "",
      item_price: "",
      available_quantity: "",
      created_at: "",
      updated_at: "",
    };

    if (localListing.item_id.length <= 5) {
      localListingErrorObject.item_id =
        "Item Id must have at least 6 characters";
      errorCount++;
    }
    if (localListing.item_description.length <= 5) {
      localListingErrorObject.item_description =
        "Item Description must have at least 6 characters";
      errorCount++;
    }
    if (localListing.item_category.length <= 5) {
      localListingErrorObject.item_category =
        "Item Category must have at least 6 characters";
      errorCount++;
    }

    if (isNaN(Number(localListing.gst))) {
      localListingErrorObject.gst = "gst rate must be a number";
      errorCount++;
    } else if (
      Number(localListing.gst) != 3 &&
      Number(localListing.gst) != 5 &&
      Number(localListing.gst) != 12 &&
      Number(localListing.gst) != 18 &&
      Number(localListing.gst) != 28
    ) {
      localListingErrorObject.gst =
        "invalid gst rate, it must be either 3, 5, 12, 18, 28";
      errorCount++;
    }

    // const hsnPattern = /^\d{4}(\d{2})?(\d{2})?$/; // Pattern for 4, 6, or 8 digits

    // if (!hsnPattern.test(localListingErrorObject.hsn)) {
    //   localListingErrorObject.hsn =
    //     "Invalid HSN code. It should be 4, 6, or 8 digits long.";
    //   errorCount++;
    // }
    if (isNaN(Number(localListing.length)) || localListing.length == 0) {
      localListingErrorObject.length = "length must be a number greater than 0";
      errorCount++;
    }
    if (isNaN(Number(localListing.width)) || localListing.width == 0) {
      localListingErrorObject.width = "width must be a number greater than 0";
      errorCount++;
    }
    if (isNaN(Number(localListing.height)) || localListing.height == 0) {
      localListingErrorObject.height = "height must be a number greater than 0";
      errorCount++;
    }

    if (
      isNaN(Number(localListing.item_price)) ||
      localListing.item_price == 0
    ) {
      localListingErrorObject.item_price =
        "item_price must be a number greater than 0";
      errorCount++;
    }

    if (
      isNaN(Number(localListing.item_weight)) ||
      localListing.item_weight == 0
    ) {
      localListingErrorObject.item_weight =
        "item_weight must be a number greater than 0";
      errorCount++;
    }

    setListingErrorObject(localListingErrorObject);

    return errorCount;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setLocalListing({
      ...localListing,
      [name]: value,
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files).slice(0, 5); // Limit to 5 images
      // const newImages = { ...uploadedImages };
      const newImages: any = {
        img_main: "http://tech.intellozene.com/Media/na.png",
        img_other1: "http://tech.intellozene.com/Media/na.png",
        img_other2: "http://tech.intellozene.com/Media/na.png",
        img_other3: "http://tech.intellozene.com/Media/na.png",
        img_other4: "http://tech.intellozene.com/Media/na.png",
      };
      const newImageFiles: any = {};
      let loadedFilesCount = 0;

      fileArray.forEach((file, index) => {
        const imageKey = `img_${index === 0 ? "main" : `other${index}`}`;
        const reader = new FileReader();
        newImageFiles[imageKey] = file;

        reader.onload = (e) => {
          if (e.target) {
            newImages[imageKey] = e.target.result; // Set the image URL
            loadedFilesCount += 1;
            // setUploadedImages({ ...newImages });

            if (loadedFilesCount === fileArray.length) {
              setUploadedImageFiles(newImageFiles);
              setUploadedImages(newImages);
            }
          }
        };
        console.log(newImages);

        reader.readAsDataURL(file);
      });

      // setUploadedImageFiles(newImageFiles);
      // setUploadedImages(newImages);
    }
  };

  // const uploadImages = async (event: React.FormEvent) => {
  //   try {
  //     const response = await listingNetworkUtil.uploadListingImages(
  //       uploadedImageFiles
  //     );
  //     console.log(response);
  //     toast.success("Successfully uploaded!", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Unknown error! Please try again.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //     });
  //   }
  // };

  const updateTransferListing = (paths: any[], transferListing: ImsListing) => {
    transferListing.img_main = "http://tech.intellozene.com/Media/na.png";
    transferListing.img_other1 = "http://tech.intellozene.com/Media/na.png";
    transferListing.img_other2 = "http://tech.intellozene.com/Media/na.png";
    transferListing.img_other3 = "http://tech.intellozene.com/Media/na.png";
    transferListing.img_other4 = "http://tech.intellozene.com/Media/na.png";

    paths.forEach((image, index) => {
      switch (index) {
        case 0:
          transferListing.img_main = image;
          break;
        case 1:
          transferListing.img_other1 = image;
          break;
        case 2:
          transferListing.img_other2 = image;
          break;
        case 3:
          transferListing.img_other3 = image;
          break;
        case 4:
          transferListing.img_other4 = image;
          break;
        default:
          break;
      }
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setShowLoadingPageForSubmission(true);
    setSubmitButtonClicked(true);
    event.preventDefault();
    console.log(localListing);
    const error_count = checkForErrors();

    if (error_count > 0) {
      toast.error(`${error_count} errors found, Fix them to continue.`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }

    let areImagesSet: boolean = false;
    Object.keys(uploadedImageFiles).forEach((key, index) => {
      if (uploadedImageFiles[key]) {
        areImagesSet = true;
      }
    });

    if (!areImagesSet) {
      toast.error(`Upload at least 1 image in order to submit listing.`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }

    const transferListing: ImsListing = _.cloneDeep(localListing);

    try {
      //uploading images first;
      try {
        const response = await listingNetworkUtil.uploadListingImages(
          uploadedImageFiles
        );
        console.log(response);
        toast.success("Images successfully uploaded!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        updateTransferListing(response.data["paths"], transferListing);
      } catch (error) {
        console.log(error);
        toast.error(
          "Unknown error while trying to upload images! Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );

        return;
      }

      // await delay(60000);

      const response = await listingNetworkUtil.addNewListing(transferListing);
      console.log(response);
      toast.success("New listing created successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowLoadingPageForSubmission(false);
      resetDataObjects();
    } catch (error) {
      setShowLoadingPageForSubmission(false);
      console.log(error);
      const err = error as AxiosError;
      console.log(err.response && err.response.data);
      if (err.response && err.response.data && err.response.status == 422) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        toast.error("Fields cannot be set empty!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unknown error! Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  return (
    <div className="overlay-test">
      <div className="sku-details-add-item">
        <div className="main-container-add-new-item">
          <div className="image-container-add-new-item">
            <div className="image-list-add-new-item">
              {[
                "img_main",
                "img_other1",
                "img_other2",
                "img_other3",
                "img_other4",
              ].map((imageKey: string) => (
                <div
                  key={imageKey}
                  className={`image-placeholder-add-item ${
                    selectedImage === imageKey ? "active" : ""
                  }`}
                  onClick={() => handleImageClick(imageKey)}
                >
                  <img
                    src={
                      uploadedImages[imageKey]
                        ? uploadedImages[imageKey].toString()
                        : "NA"
                    }
                    alt={`Image ${imageKey}`}
                    onError={() => handleImageError(imageKey)}
                  />
                </div>
              ))}
            </div>

            <div className="details-button-container mt-2">
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the input field
                id="imageUpload"
              />
              <label htmlFor="imageUpload" className="btn btn-primary">
                Browse Images
              </label>
            </div>
          </div>

          <div className="details-container-add-new-item">
            <div className="details-card-add-new-item">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className={getClassForError(listingErrorObject.item_id)}>
                  <label>Item ID</label>
                  <input
                    type="text"
                    name="item_id"
                    value={localListing.item_id || ""}
                    onChange={handleChange}
                    className="input-field"
                  />
                  {listingErrorObject.item_id && (
                    <span className="tooltip-text">
                      {getToolTipForError(listingErrorObject.item_id)}
                    </span>
                  )}
                </div>
                <div className="grid-1-cells">
                  <div
                    className={getClassForError(
                      listingErrorObject.item_description
                    )}
                  >
                    <label>Item Description</label>
                    <input
                      type="text"
                      name="item_description"
                      value={localListing.item_description || ""}
                      onChange={handleChange}
                      className="input-field"
                    />
                    {listingErrorObject.item_id && (
                      <span className="tooltip-text">
                        {getToolTipForError(
                          listingErrorObject.item_description
                        )}
                      </span>
                    )}
                  </div>
                </div>

                <div className="grid-2-cells">
                  <div
                    className={getClassForError(
                      listingErrorObject.item_category
                    )}
                  >
                    <label>Category</label>
                    <input
                      type="text"
                      name="item_category"
                      value={localListing.item_category || ""}
                      onChange={handleChange}
                      className="input-field"
                    />
                    {listingErrorObject.item_id && (
                      <span className="tooltip-text">
                        {getToolTipForError(listingErrorObject.item_category)}
                      </span>
                    )}
                  </div>
                  <div
                    className={getClassForError(listingErrorObject.item_price)}
                  >
                    <label>Item Price</label>
                    <input
                      type="text"
                      name="item_price"
                      value={localListing.item_price || ""}
                      onChange={handleChange}
                      className="input-field"
                    />
                    {listingErrorObject.item_id && (
                      <span className="tooltip-text">
                        {getToolTipForError(listingErrorObject.item_price)}
                      </span>
                    )}
                  </div>
                </div>

                <div className="grid-2-cells">
                  <div className={getClassForError(listingErrorObject.gst)}>
                    <label>GST</label>
                    <input
                      type="text"
                      name="gst"
                      value={localListing.gst || ""}
                      onChange={handleChange}
                      className="input-field"
                    />
                    <span className="tooltip-text">
                      {getToolTipForError(listingErrorObject.gst)}
                    </span>
                  </div>

                  <div className={getClassForError(listingErrorObject.hsn)}>
                    <label>HSN code</label>
                    <input
                      type="text"
                      name="hsn"
                      value={localListing.hsn || ""}
                      onChange={handleChange}
                      className="input-field"
                    />
                    <span className="tooltip-text">
                      {getToolTipForError(listingErrorObject.hsn)}
                    </span>
                  </div>
                </div>

                <div className="grid-4-cells">
                  <div
                    className={getClassForError(listingErrorObject.item_weight)}
                  >
                    <label>Item weight</label>
                    <input
                      type="text"
                      name="item_weight"
                      value={localListing.item_weight || ""}
                      onChange={handleChange}
                      className="input-field"
                    />
                    <span className="tooltip-text">
                      {getToolTipForError(listingErrorObject.item_weight)}
                    </span>
                  </div>

                  <div className={getClassForError(listingErrorObject.length)}>
                    <label>Length</label>
                    <input
                      type="text"
                      name="length"
                      value={localListing.length || ""}
                      onChange={handleChange}
                      className="input-field"
                    />
                    <span className="tooltip-text">
                      {getToolTipForError(listingErrorObject.length)}
                    </span>
                  </div>

                  <div className={getClassForError(listingErrorObject.width)}>
                    <label>Width</label>
                    <input
                      type="text"
                      name="width"
                      value={localListing.width || ""}
                      onChange={handleChange}
                      className="input-field"
                    />
                    <span className="tooltip-text">
                      {getToolTipForError(listingErrorObject.width)}
                    </span>
                  </div>

                  <div className={getClassForError(listingErrorObject.height)}>
                    <label>Height</label>
                    <input
                      type="text"
                      name="height"
                      value={localListing.height || ""}
                      onChange={handleChange}
                      className="input-field"
                    />
                    <span className="tooltip-text">
                      {getToolTipForError(listingErrorObject.height)}
                    </span>
                  </div>
                </div>

                <div className="details-button-container mt-2">
                  <button type="button" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}

      {showLoadingPageForSubmission && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Creating Listing, please wait...
        </div>
      )}
    </div>
  );
}
