import React, { useState, useEffect } from "react";
import "./CSS/ChallanPublishedItemsView.css";
import {
  CostPriceTimelineItem,
  ImsChallanItem,
  ImsInvoiceItem,
  Listing,
  ListingPage,
  PurchaseOrderItemWithLisiting,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

import { toast } from "react-toastify";
import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";

const ListingCard: React.FC<{
  challanListing: ImsChallanItem;
  handleCardClick: (listing: ImsChallanItem) => void;
}> = ({ challanListing, handleCardClick }) => (
  <div
    className="invoice-listing-card"
    onClick={() => handleCardClick(challanListing)}
  >
    <div className="invoice-listings-img-container">
      <img src={challanListing.listing.img_main} alt="Product Image" />
    </div>

    <div className="listings-view-card-title">
      <h3
        className={`sku-id-title ${
          challanListing.listing.available_quantity === 0
            ? "not-available"
            : "available"
        }`}
      >
        {challanListing.listing.item_id}
      </h3>
    </div>

    <p>
      Current Available Quantity: {challanListing.listing.available_quantity}
    </p>
    <p>Current Price : {challanListing.listing.item_price}</p>
    <p className="mt-2">
      <b>Challan Quantity : {challanListing.item_quantity}</b>
    </p>

    <p className="last-modified mt-2">
      Last Modified: {DateTimeUtils.formatDateTime(challanListing.updated_at)}
    </p>
  </div>
);

type ListingsViewProps = {
  publishedListings: ImsChallanItem[];
  onCardClick: (selectedListing: ImsChallanItem) => void;
};

export default function ChallanPublishedItemsView({
  publishedListings,
  onCardClick,
}: ListingsViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const handleCardClick = async (listing: ImsChallanItem) => {
    onCardClick(listing);
  };

  return (
    <div className="invoice-published-listings-container-child">
      {publishedListings && publishedListings.length > 0 && (
        <div className="invoice-listings-card-container">
          {publishedListings.map((publishedListing, index) => (
            <ListingCard
              key={index}
              challanListing={publishedListing}
              handleCardClick={handleCardClick}
            />
          ))}
        </div>
      )}

      {publishedListings && publishedListings.length == 0 && (
        <div className="no-items-view">Nothing to Display</div>
      )}
    </div>
  );
}
