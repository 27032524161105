import React, { useEffect, useState } from "react";

import "./CSS/ChallanHome.css";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";

import ChallanCreate from "./ChallanCreate";
import ChallanAudit from "./ChallanAudit";
import ChallanRecivable from "./ChallanRecievable";
import ChallanDelivery from "./ChallanDelivery";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function ChallanHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(ActiveTab__Action("challans"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/challans/${item}`);
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div>
      <div className="nav-container">
        <nav className="navbar-listings">
          <ul className="navbar-menu">
            <li
              className={`navbar-item ${
                selectedItem === "recievable" ? "active" : ""
              }`}
              onClick={() => handleItemClick("recievable")}
            >
              Recievable Challans
            </li>
            <li
              className={`navbar-item ${
                selectedItem === "audit" ? "active" : ""
              }`}
              onClick={() => handleItemClick("audit")}
            >
              Audit Challans
            </li>
            <li
              className={`navbar-item ${
                selectedItem === "delivery" ? "active" : ""
              }`}
              onClick={() => handleItemClick("delivery")}
            >
              Delivery Challans
            </li>
            <li
              className={`navbar-item ${
                selectedItem === "create" ? "active" : ""
              }`}
              onClick={() => handleItemClick("create")}
            >
              Create/Add New Challan
            </li>
          </ul>
        </nav>
      </div>

      {selectedItem == "create" && <ChallanCreate />}
      {selectedItem == "recievable" && <ChallanRecivable />}
      {selectedItem == "audit" && <ChallanAudit />}
      {selectedItem == "delivery" && <ChallanDelivery />}
    </div>
  );
}

export default ChallanHome;
