import React, { useState, useEffect } from "react";
import "./CSS/InvoicesEmbeddedListingsView.css";
import { ImsListing } from "../../atomic_components/types";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

import { toast } from "react-toastify";
import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";

const ListingCard: React.FC<{
  listing: ImsListing;
  handleCardClick: (listing: ImsListing) => void;
}> = ({ listing, handleCardClick }) => (
  <div
    className="invoice-listing-card"
    onClick={() => handleCardClick(listing)}
  >
    <div className="invoice-listings-img-container">
      <img src={listing.img_main} alt="Product Image" />
    </div>

    <div className="listings-view-card-title">
      <h3
        className={`sku-id-title ${
          listing.available_quantity === 0 ? "not-available" : "available"
        }`}
      >
        {listing.item_id}
      </h3>
    </div>

    <p>Description : {listing.item_description}</p>
    <p className="purchase-order-vendor-listing-card-text">
      Available Quantity: {listing.available_quantity}
    </p>

    <p className="purchase-order-vendor-listing-card-text">
      Item Price : {listing.item_price}
    </p>
  </div>
);

type ListingsViewProps = {
  listings: ImsListing[];
  onCardClick: (selectedListing: ImsListing) => void;
};

export default function InvoicesEmbeddedListingsView({
  listings,
  onCardClick,
}: ListingsViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const handleCardClick = async (listing: ImsListing) => {
    onCardClick(listing);
  };

  return (
    <div className="invoice-listings-container-child">
      {listings && listings.length > 0 && (
        <div className="invoice-listings-card-container">
          {listings.map((listing, index) => (
            <ListingCard
              key={index}
              listing={listing}
              handleCardClick={handleCardClick}
            />
          ))}
        </div>
      )}

      {listings && listings.length == 0 && (
        <div className="no-items-view">Nothing to Display</div>
      )}
    </div>
  );
}
