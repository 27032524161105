import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/InvoicesAddItem.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";

import {
  ImsInvoice,
  ImsInvoiceItem,
  ImsListing,
  PoItem,
  PurchaseOrder,
  SearchParams,
  Specialty,
  Vendor,
  VendorListing,
  InvoiceError,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import Header from "../Header";
import VendorListingsView from "../Vendors/VendorListingsView";
import VendorsListingSearchBar from "../Vendors/VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
// import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import InvoiceNetworkUtils from "./NetworkUtils/InvoiceNetworkUtils";
import InvoicesEmbeddedListingsView from "./InvoicesEmbeddedListingsView";
import ListingsSearchBar from "../ListingsAndInventory/ListingsSearchBar";
import InvoicesSearchBarAddItem from "./InvoicesSearchBarAddItem";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
  sub_category: string;
  invoice_id: string;
  vendor_id: string;
}

function InvoicesAddItem() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const { category, sub_category, invoice_id } = useParams<RouteParams>();
  const [selectedListing, setSelectedListing] = useState<ImsListing | null>(
    null
  );
  // const [selectedinvoiceItem, setSelectedinvoiceItem] =
  //   useState<invoiceItem | null>(null);
  // const [isItemPartOfPurchaseOrder, setIsItemPartOfPurchaseOrder] =
  //   useState<boolean>(false);
  // const [invoiceItems, setinvoiceItems] = useState<
  //   invoiceItem[]
  // >([]);
  const [invoiceItems, setInvoiceItems] = useState<ImsInvoiceItem[]>([]);
  const [selectedInvoiceItem, setSelectedInvoiceItem] =
    useState<ImsInvoiceItem | null>(null);
  const [isItemPartOfInvoice, setIsItemPartOfInvoice] =
    useState<boolean>(false);
  const [currentInvoice, setCurrentInvoice] = useState<ImsInvoice | null>(null);
  const [modalShow, setModalShow] = useState(false);
  const [emptyModalShow, setEmptyModalShow] = useState(false);
  const [listings, setListings] = useState<ImsListing[]>([]);
  const [currentVendor, setCurrentVendor] = useState<Vendor>({
    id: 0,
    vendor_name: "",
    vendor_address_line: "",
    vendor_city: "",
    vendor_state: "",
    vendor_postal_code: "",
    vendor_gstin: "",
    vendor_contact_number: "",
    created_by: "",
    modified_by: "",
    created_at: "",
    updated_at: "",
    specialties: [],
  });

  const [localVendor, setLocalVendor] = useState<Vendor>(currentVendor);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);
  const [showWaitingPage, setShowWaitingPage] = useState<boolean>(false);
  const [requiredQuantity, setRequiredQuantity] = useState<number>(0);
  const [finalPrice, setFinalprice] = useState<number>(0);
  const [originalPrice, setOriginalprice] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const invoiceNetworkUtil = new InvoiceNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  useEffect(() => {
    const fetchInvoiceByInvoiceId = async (invoiceId: string) => {
      try {
        const response = await invoiceNetworkUtil.getInvoiceByInvoiceId(
          invoiceId
        );
        // console.log(response.data);
        setCurrentInvoice(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    fetchInvoiceByInvoiceId(invoice_id);
  }, [invoice_id]);

  useEffect(() => {
    const getinvoiceItemsByInvoiceId = async (invoiceId: number) => {
      try {
        const response = await invoiceNetworkUtil.getInvoiceItems(
          invoiceId.toString()
        );
        // console.log(response.data);
        setInvoiceItems(response.data);
        setShowLoadingPage(false);
      } catch (error) {
        setShowLoadingPage(false);
        console.error("Error in getting invoice items by invoice id : ", error);
      }
    };

    if (currentInvoice) {
      if (currentInvoice.invoice_status == "PUBLISHED") {
        history.push(`/invoices/published/${invoice_id}`);
      } else {
        getinvoiceItemsByInvoiceId(currentInvoice.id);
        handleSearch("");
      }
    }
  }, [currentInvoice]);

  useEffect(() => {
    if (!modalShow) {
      setRequiredQuantity(0);
      setFinalprice(0);
      setOriginalprice(0);
      setIsItemPartOfInvoice(false);
      setSelectedListing(null);
      setSelectedInvoiceItem(null);
    }
  }, [modalShow]);

  useEffect(() => {
    let sku: string = "";
    if (selectedListing) {
      sku = selectedListing.item_id;
    } else if (selectedInvoiceItem) {
      sku = selectedInvoiceItem.item_id;
    }

    if (sku != "") {
      const obj: ImsInvoiceItem | undefined = invoiceItems.find(
        (invoiceItem) => invoiceItem.item_id == sku
      );
      if (obj) {
        setIsItemPartOfInvoice(true);
        setRequiredQuantity(obj.item_quantity);
        setOriginalprice(obj.item_actual_price);
        setFinalprice(obj.item_final_price);
      } else {
        const obj: ImsListing | undefined = listings.find(
          (listing) => listing.item_id == sku
        );
        if (obj) {
          setOriginalprice(obj.item_price);
          setFinalprice(obj.item_price);
        }
      }
    }
  }, [selectedListing, selectedInvoiceItem]);

  const handleRequiredQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setRequiredQuantity(parseInt(value));
  };

  const handleFinalPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setFinalprice(parseInt(value));
  };

  const onCardClick = (currentListing: ImsListing) => {
    // console.log("card clicked: ", currentListing);
    if (currentListing.available_quantity == 0) setEmptyModalShow(true);
    else {
      setModalShow(true);
      setSelectedListing(currentListing);
    }
  };

  const handleOnClickAdd = () => {
    if (requiredQuantity <= 0) {
      toast.error("Please enter a non-zero quantity to add.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }
    addToInvoice(
      selectedListing,
      selectedInvoiceItem,
      requiredQuantity,
      finalPrice
    );
    setModalShow(false);
  };

  const addToInvoice = async (
    listing: ImsListing | null,
    invoiceItem: ImsInvoiceItem | null,
    qty: number,
    fp: number
  ) => {
    setShowLoadingPage(true);
    if (listing || invoiceItem) {
      const sku: string = listing
        ? listing.item_id
        : invoiceItem
        ? invoiceItem.item_id
        : "";
      if (sku == "") return;

      console.log(fp, listing ? listing.item_price : 0);

      //check sku in invoiceItems
      const obj: ImsInvoiceItem | undefined = _.cloneDeep(
        invoiceItems.find((invoiceItem) => invoiceItem.item_id == sku)
      );

      let newinvoiceItem: any;
      let isNewItem: boolean = true;
      if (obj) {
        obj.item_quantity = qty;
        newinvoiceItem = obj;
        isNewItem = false;
      } else {
        newinvoiceItem = {
          invoice_id: invoice_id,
          item_id: sku,
          item_actual_price: listing ? listing.item_price : 0,
          item_final_price: fp,
          item_quantity: qty,
          created_by: userDetailsFromStore.user_details.userId,
        };
        console.log(newinvoiceItem);
      }

      try {
        const response = await invoiceNetworkUtil.addNewItemToInvoice(
          newinvoiceItem,
          invoice_id
        );
        // console.log(response.data);
        if (isNewItem) {
          setInvoiceItems((prevList) => [...prevList, response.data]);
        } else {
          const updatedList = invoiceItems.map((invoiceItem, index) =>
            invoiceItem.item_id === sku ? response.data : invoiceItem
          );
          setInvoiceItems(updatedList);
        }
        setShowLoadingPage(false);
        toast.success("Success.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        // setCurrentPurchaseOrder(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
        setShowLoadingPage(false);
        toast.error("Error while adding, try again.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const onInvoiceItemClick = (invoiceItem: ImsInvoiceItem) => {
    setModalShow(true);
    setSelectedInvoiceItem(invoiceItem);
    // setSelectedListing(invoiceItem.listing);
    setRequiredQuantity(invoiceItem.item_quantity);
  };

  const handleOnClickDelete = async () => {
    setModalShow(false);
    if (selectedInvoiceItem || selectedListing) {
      setShowLoadingPage(true);
      const sku: string = selectedInvoiceItem
        ? selectedInvoiceItem.item_id
        : selectedListing
        ? selectedListing.item_id
        : "";
      const itemToDelete = {
        invoice_id: invoice_id,
        item_id: sku,
      };
      try {
        const response = await invoiceNetworkUtil.deleteItemFromInvoice(
          itemToDelete,
          invoice_id
        );

        const updatedList = invoiceItems.filter(
          (invoiceItem, index) => invoiceItem.item_id != sku
        );
        setInvoiceItems(updatedList);
        toast.success("Successfully deleted.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        setShowLoadingPage(false);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
        toast.error("Error while deleting, try again.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        setShowLoadingPage(false);
      }
    }
  };

  const publishInvoice = async () => {
    try {
      const response = await invoiceNetworkUtil.publishInvoice(invoice_id);
      if (currentInvoice) {
        const updatedIvoice = {
          ...currentInvoice,
          invoice_status: "PUBLISHED",
        };
        setCurrentInvoice(updatedIvoice);
      }
      toast.success("Published successfully", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in publishing invoice :", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 406) {
        const test = err.response.data as InvoiceError;

        if (test.oosSKUs && test.oosSKUs.length > 0) {
          // Display out-of-stock SKUs if they are provided in the error response
          toast.error(
            `Looks like your invoice was pending for long, following skus has less inventory than booked by invoice: ${test.oosSKUs.join(
              ", "
            )}`,
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
            }
          );
        }
      } else {
        toast.error("Error while publishing, try again.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const republishInvoice = async () => {
    try {
      const response = await invoiceNetworkUtil.republishInvoice(
        invoice_id,
        userDetailsFromStore.user_details.userId
      );
      if (currentInvoice) {
        const updatedIvoice = {
          ...currentInvoice,
          status: "PENDING_PUBLISHED",
        };
        setCurrentInvoice(updatedIvoice);
      }
      toast.success("Re-Published successfully", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in republishInvoice :", error);
      toast.error("Error while re-publishing, try again.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // console.log(requiredQuantity);
      handleOnClickAdd();
    }
  };

  const handleSearch = (query: string) => {
    setListings([]);
    setIsLoading(true);
    search(query, 1);
  };

  const search = async (query: string, pageNumber: number, count?: number) => {
    try {
      const response = await listingNetworkUtil.getListingsBySearchKeyword(
        query,
        pageNumber,
        count ? count : 100
      );

      const newListings: Array<ImsListing> = response.data["content"];
      setListings((prevList) => [...prevList, ...newListings]);
      setIsLoading(false);
    } catch (error) {
      console.log("Printing error from ListingsHome");
      console.error("Error:", error);
    }
  };

  return (
    <div className="overlay">
      <div className="vendor-details">
        <Header />
        {currentInvoice && (
          <div className="main-container">
            <div className="vendor-action-container">
              <div className="vendor-basics vendors-info-card add-items-heading">
                {currentInvoice.invoice_status == "PENDING" ? (
                  <div>Add Items to Invoice - [ {invoice_id} ]</div>
                ) : (
                  <div>Modify Invoice [ {invoice_id} ]</div>
                )}
              </div>
              {invoiceItems.length > 0 && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Invoice Items</h4>
                    <div className="vendor-details-button-container">
                      {currentInvoice.invoice_status == "PENDING" && (
                        <div>
                          <button
                            type="button"
                            onClick={() => setShowPublishConfirmation(true)}
                          >
                            Publish
                          </button>
                          {/* <PDFDownloadLink
                            document={<PurchaseOrderPDF poItems={poItems} />}
                            fileName="test"
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? "Loading document..." : "Download PDF"
                            }
                          </PDFDownloadLink> */}
                        </div>
                      )}
                      {currentInvoice.invoice_status ==
                        "MARKED_MODIFICATION" && (
                        <div>
                          <button type="button" onClick={republishInvoice}>
                            Re-Publish
                          </button>
                          {/* <PDFDownloadLink
                            document={<PurchaseOrderPDF poItems={poItems} />}
                            fileName="test"
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? "Loading document..." : "Download PDF"
                            }
                          </PDFDownloadLink> */}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid-1-cells">
                    {invoiceItems.length != 0 && (
                      <div className="grid-2-cells">
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Items : </b>{" "}
                            {invoiceItems.length.toString()}
                          </div>
                        </Alert>
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Quantity :</b>{" "}
                            {invoiceItems.reduce(
                              (total, invoiceItem) =>
                                total + invoiceItem.item_quantity,
                              0
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}

                    {invoiceItems.map((invoiceItem, index) => {
                      return (
                        <div
                          className="po-item-card"
                          key={index}
                          onClick={() => onInvoiceItemClick(invoiceItem)}
                        >
                          <div>
                            <b>{invoiceItem.item_id}</b> -{" "}
                            {invoiceItem.item_quantity.toString()}
                          </div>
                          <div>
                            Original Unit Price -{" "}
                            {invoiceItem.item_actual_price.toString()}
                          </div>
                          <div>
                            Final Unit Price -{" "}
                            {invoiceItem.item_final_price.toString()}
                          </div>
                          <div className="last-modified">
                            {DateTimeUtils.formatDateTime(
                              invoiceItem.updated_at
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {currentInvoice && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Invoice Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Invoice status</label>
                      {currentInvoice.invoice_status == "PENDING" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="danger">
                          {currentInvoice.invoice_status}
                        </Badge>
                      )}
                      {currentInvoice.invoice_status ==
                        "MARKED_MODIFICATION" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="warning">
                          MARKED FOR MODIFICATION
                        </Badge>
                      )}
                      {/* <input
                      type="text"
                      name="po_status"
                      value={currentInvoice.invoice_status}
                      disabled={true}
                    /> */}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Invoice Id</label>
                      <input
                        type="text"
                        name="invoice_id"
                        value={currentInvoice.id}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Customer Name</label>
                      <input
                        type="text"
                        name="customer_name"
                        value={currentInvoice.customer_name}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Customer Contact Number</label>
                      <input
                        type="text"
                        name="customer_contact_number"
                        value={currentInvoice.customer_contact_number}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Customer Billing Address</label>
                      <input
                        type="text"
                        name="customer_billing_address"
                        value={currentInvoice.customer_billing_address}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Customer Shipping Address</label>
                      <input
                        type="text"
                        name="customer_shipping_address"
                        value={currentInvoice.customer_shipping_address}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Customer GSTIN</label>
                      <input
                        type="text"
                        name="customer_gstin"
                        value={currentInvoice.customer_gstin}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Payment Mode</label>
                      <input
                        type="text"
                        name="payment_mode"
                        value={currentInvoice.payment_mode}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Invoice Date</label>
                      <input
                        type="text"
                        name="invoice_date"
                        value={DateTimeUtils.formatDateOnly(
                          currentInvoice.invoice_date
                        )}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="po_created_by"
                        value={currentInvoice.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="po_date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentInvoice.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="invoice-listings-container">
              <div className="vendor-listings-search-bar-container ml-4">
                <InvoicesSearchBarAddItem
                  onSearch={handleSearch}
                ></InvoicesSearchBarAddItem>
              </div>

              {isLoading ? (
                <div className="invoice-skus-spinner-container">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div className="listings-container">
                  {listings.length != 0 && (
                    <div className="all-listings-container">
                      <InvoicesEmbeddedListingsView
                        listings={listings}
                        onCardClick={onCardClick}
                      />
                    </div>
                  )}
                  {listings.length == 0 && (
                    <div className="no-items-view">Nothing to Display</div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setModalShow(false)}
      >
        {/* {selectedListing && selectedListing.available_quantity > 0 ? ( */}
        <Modal.Body>
          <div className="required-quantity-final-price-container">
            <div className="invoice-quantity-container">
              <div>
                <div>Item Count</div>
              </div>
              <input
                type="number"
                name="order_quantity"
                value={requiredQuantity.toString()}
                onChange={(e) => handleRequiredQuantityChange(e)}
                onKeyPress={handleKeyPress}
              />
            </div>

            <div className="invoice-quantity-container mt-4">
              <div>
                <div>Original Unit Price</div>
              </div>
              <input
                type="number"
                name="order_quantity"
                value={originalPrice.toString()}
                onChange={(e) => handleFinalPriceChange(e)}
                onKeyPress={handleKeyPress}
                disabled={true}
              />
            </div>

            <div className="invoice-quantity-container mt-4">
              <div>
                <div>Final Unit Price</div>
              </div>
              <input
                type="number"
                name="order_quantity"
                value={finalPrice.toString()}
                onChange={(e) => handleFinalPriceChange(e)}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
        </Modal.Body>
        {/* ) : (
          <Modal.Body>
            <div className="required-quantity-final-price-container">
              Unable to add to invoice ! Selected Product has 0 available
              inventory.
            </div>
          </Modal.Body>
        )} */}
        <Modal.Footer>
          <div className="add-item-quantity-card-button-container">
            <Button className="mr-2" onClick={() => setModalShow(false)}>
              Cancel
            </Button>
            <Button onClick={handleOnClickAdd}>Add</Button>
            {isItemPartOfInvoice && (
              <Button className="ml-2" onClick={handleOnClickDelete}>
                Delete
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={emptyModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setEmptyModalShow(false)}
      >
        <Modal.Body>
          <div className="required-quantity-final-price-container">
            Unable to add to invoice ! Selected Product has 0 available
            inventory.
          </div>
        </Modal.Body>
      </Modal>

      {showLoadingPage && (
        <div className="loading-page-horizontal">
          <Spinner animation="grow" className="mr-2" />
          <Spinner animation="grow" className="mr-2" />
          <Spinner animation="grow" className="mr-2" />
        </div>
      )}

      <Modal
        show={showPublishConfirmation}
        onHide={() => setShowPublishConfirmation(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Publish Invoice ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to publish this invoice ? this will lead to
          inventory subtraction.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowPublishConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={publishInvoice}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default InvoicesAddItem;
