import axios, { AxiosError } from "axios";
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";
import { PurchaseOrder, Specialty, Vendor, VendorListing } from "../../../atomic_components/types";


class ChallanNetworkUtils {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any, location:any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create();

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            const returnUrl = encodeURIComponent(
              location.pathname + location.search
            );
            history.push(`/login?returnUrl=${returnUrl}`);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async createNewAuditChallan(body: any) {
    try {
      let url = `${requestHeaders.rooturlIms}${requests.createAuditChallan}`;
      const response = await this.api.post(url, body, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from createAuditChallan");
      console.error("Error:", error);
      throw error;
    }
  }

  async createNewDeliveryChallan(body: any) {
    try {
      let url = `${requestHeaders.rooturlIms}${requests.createDeliveryChallan}`;
      const response = await this.api.post(url, body, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from createDeliveryChallan");
      console.error("Error:", error);
      throw error;
    }
  }
  async createNewRecievableChallan(body: any) {
    try {
      let url = `${requestHeaders.rooturlIms}${requests.createRecievableChallan}`;
      const response = await this.api.post(url, body, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from createNewRecievableChallan");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAuditChallanByChallanId(challanId : string) {
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getAuditChallanByChallanId}${challanId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAuditChallanByChallanId");
      console.error("Error:", error);
      throw error;
    }
  }




  async getDeliveryChallanByChallanId(challanId : string) {
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getDeliveryChallanByChallanId}${challanId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getDeliveryChallanByChallanId");
      console.error("Error:", error);
      throw error;
    }
  }


  async getRecievableChallanByChallanId(challanId : string) {
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getRecievableChallanByChallanId}${challanId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getRecievableChallanByChallanId");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAuditChallanItems(challanId : string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getAuditChallanItems}${challanId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAuditChallanItems");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAuditChallanItemsWithListing(challanId : string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getAuditChallanItemsWithListing}${challanId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAuditChallanItems");
      console.error("Error:", error);
      throw error;
    }
  }

  async getDeliveryChallanItems(challanId : string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getDeliveryChallanItems}${challanId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getDeliveryChallanItems");
      console.error("Error:", error);
      throw error;
    }
  }
  async getDeliveryChallanItemsWithListing(challanId : string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getDeliveryChallanItemsWithListing}${challanId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getDeliveryChallanItems");
      console.error("Error:", error);
      throw error;
    }
  }
  async getRecievableChallanItems(challanId : string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getRecievableChallanItems}${challanId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getRecievableChallanItems");
      console.error("Error:", error);
      throw error;
    }
  }
  async getRecievableChallanItemsWithListing(challanId : string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getRecievableChallanItemsWithListing}${challanId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getRecievableChallanItems");
      console.error("Error:", error);
      throw error;
    }
  }


  async addToAuditChallan(challanItem : any, challanId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.addItemToAuditChallan}${challanId}`;
      const response = await this.api.put(url, challanItem, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from addToAuditChallan");
      console.error("Error:", error);
      throw error;
    }
  }

  async addToDeliveryChallan(challanItem : any, challanId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.addItemToDeliveryChallan}${challanId}`;
      const response = await this.api.put(url, challanItem, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from addItemToDeliveryChallan");
      console.error("Error:", error);
      throw error;
    }
  }
  async addToRecievableChallan(challanItem : any, challanId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.addItemToRecievableChallan}${challanId}`;
      const response = await this.api.put(url, challanItem, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from addItemToDeliveryChallan");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async deleteItemFromAuditChallan(challanItem : any, challanId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.deleteItemFromAuditChallan}${challanId}`;
      const response = await this.api.post(url, challanItem, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from deleteItemFromAuditChallan");
      console.error("Error:", error);
      throw error;
    }
  }
  async deleteItemFromDeliveryChallan(challanItem : any, challanId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.deleteItemFromDeliveryChallan}${challanId}`;
      const response = await this.api.post(url, challanItem, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from deleteItemFromDeliveryChallan");
      console.error("Error:", error);
      throw error;
    }
  }
  async deleteItemFromRecievableChallan(challanItem : any, challanId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.deleteItemFromRecievableChallan}${challanId}`;
      const response = await this.api.post(url, challanItem, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from deleteItemFromRecievableChallan");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async getInvoiceItems(invoiceId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getAddedItemsOfAInvoice}${invoiceId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getInvoiceItems");
      console.error("Error:", error);
      throw error;
    }
  }

  async getInvoiceItemsWithListing(invoiceId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getAddedItemsOfAInvoiceWithListing}${invoiceId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getInvoiceItemsWithListing");
      console.error("Error:", error);
      throw error;
    }
  }

  async publishAuditChallan(challanId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.publishAuditChallan}${challanId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from publishAuditChallan");
      console.error("Error:", error);
      throw error;
    }
  }

  async publishDeliveryChallan(challanId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.publishDeliveryChallan}${challanId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from publishAuditChallan");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async publishRecievableChallan(challanId: string){
    try {
      let url = `${requestHeaders.rooturlIms}${requests.publishRecievableChallan}${challanId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from publishRecievableChallan");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async republishInvoice(poId: string, userId : string){
    try {
      // const queryParams: Record<string, any> = {
      //   userId: userId, // New page value
      // };
      let url = `${requestHeaders.rooturl}${requests.republishPo}${poId}?userId=${userId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from republishPo");
      console.error("Error:", error);
      throw error;
    }
  }



  async markPoForModification(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.markPoForModification}${poId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from markPoForModification");
      console.error("Error:", error);
      throw error;
    }
  }

  async markPoForCancellation(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.markPoForCancellation}${poId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from markPoForCancellation");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAuditChallansPaginated(
      searchKeyword: string,
      pageNumber: number,
      count: number
    ) {
      const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
      };
      try {
        let url = `${requestHeaders.rooturlIms}${requests.getAuditChallansPaginated}?search_keyword=${searchKeyword}`;
        if (queryParams) {
          const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
          url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
      } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getAuditChallansPaginated");
        console.error("Error:", error);
        throw error;
      }
  }

  async getDeliveryChallansPaginated(
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, // New count value
    };
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getDeliveryChallansPaginated}?search_keyword=${searchKeyword}`;
      if (queryParams) {
        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");
        url += `&${queryString}`;
      }
      const response = await this.api.get(url, { withCredentials: true });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getDeliveryChallansPaginated");
      console.error("Error:", error);
      throw error;
    }
  }
  async getRecievableChallansPaginated(
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, // New count value
    };
    try {
      let url = `${requestHeaders.rooturlIms}${requests.getRecievableChallansPaginated}?search_keyword=${searchKeyword}`;
      if (queryParams) {
        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");
        url += `&${queryString}`;
      }
      const response = await this.api.get(url, { withCredentials: true });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getRecievableChallansPaginated");
      console.error("Error:", error);
      throw error;
    }
  }


  async uploadChallanImages(uploadedImages: any) {
    const formData = new FormData();

    // Object.values(uploadedImages).forEach((image) => {
    //   formData.append("images[]", image);
    // });

    Object.keys(uploadedImages).forEach((key, index) => {
      if (uploadedImages[key]) {
        // Ensure the image is not null
        formData.append(`images[${index}]`, uploadedImages[key]);
      }
    });

    let url = `${requestHeaders.rooturlIms}${requests.uploadRecievableChallanImages}`;

    try {
      const response = await this.api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  
}

export default ChallanNetworkUtils;
