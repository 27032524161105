import React, { useEffect, useState } from "react";

import "./CSS/InAndOutReports.css";

import { ImsInventoryLogs, SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Header from "../Header";
import ChallanNetworkUtils from "../ChallanRecievables/NetworkUtils/ChallanNetworkUtils";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";
import { Badge, Button, Spinner, Table } from "react-bootstrap";
import DateTimeUtils from "../../utils/DateTimeUtils";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function InaAndOutReports() {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  useEffect(() => {
    dispatch(ActiveTab__Action("inventory"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/inventory/secondaryListings/${item}`);
  };

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [inventoryLogs, setInventoryLogs] = useState<ImsInventoryLogs[]>([]);

  const getInOutReportPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response = await listingNetworkUtil.fetchInOutReportPaginated(
        fromHandleSearch == true ? searchString : searchParams.search_string,
        pageNumber,
        500
      );
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setInventoryLogs((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetching in out report :", error);
      setIsLoading(false);
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  useEffect(() => {
    getInOutReportPaginated("", 1, false);
  }, []);

  const getRouteLink = (inventoryLogItem: ImsInventoryLogs) => {
    if (inventoryLogItem.document_type == "INVOICE") {
      return `/invoices/published/${inventoryLogItem.invoice_challan_id}`;
    } else if (inventoryLogItem.document_type == "DELIVERY CHALLAN") {
      return `/challans/published/delivery/${inventoryLogItem.invoice_challan_id}`;
    } else if (inventoryLogItem.document_type == "AUDIT CHALLAN") {
      return `/challans/published/audit/${inventoryLogItem.invoice_challan_id}`;
    } else if (inventoryLogItem.document_type == "RECIEVABLE CHALLAN") {
      return `/challans/published/recievable/${inventoryLogItem.invoice_challan_id}`;
    }
  };

  const getOption = (documentType: string) => {
    if (documentType == "AUDIT CHALLAN") return "warning";
    else if (documentType == "DELIVERY CHALLAN") return "info";
    else if (documentType == "RECIEVABLE CHALLAN") return "success";
    else if (documentType == "INVOICE") return "primary";
    return "primary";
  };
  const getConvertedDoumentType = (documentType: string) => {
    if (documentType == "AUDIT CHALLAN") return "Audit Challan";
    else if (documentType == "DELIVERY CHALLAN") return "Delivery Challan";
    else if (documentType == "RECIEVABLE CHALLAN") return "Recievable Challan";
    else if (documentType == "INVOICE") return "Invoice";
    return "";
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getInOutReportPaginated("", searchParams.current_page_num + 1, false);
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  return (
    <div className="overlay-in-out-reports-home">
      <Header />
      {inventoryLogs.length > 0 ? (
        <div className="purchase-orders-table-container">
          <div className="logs-table-container">
            <Table
              striped
              bordered
              hover
              size="sm"
              className="sticky-header mt-2"
            >
              <thead>
                <tr>
                  <th>Challan/Invoice Id</th>
                  <th>Document Type</th>
                  <th>Item Id</th>
                  <th>Item Quantity</th>
                  <th>Added/Removed</th>
                  <th>Date Published</th>
                  <th>Created By</th>
                </tr>
              </thead>
              <tbody>
                {inventoryLogs.map((inventoryLog, index) => {
                  return (
                    <tr className="purchase-orders-search-bar-row" key={index}>
                      <td className="invoice-id-link">
                        <a href={getRouteLink(inventoryLog)}>
                          {inventoryLog.invoice_challan_id}
                        </a>
                      </td>
                      <td>
                        <Badge bg={getOption(inventoryLog.document_type)}>
                          {getConvertedDoumentType(inventoryLog.document_type)}
                        </Badge>
                      </td>
                      <td className="invoice-id-link">
                        <a href={`/listings/all/${inventoryLog.item_id}`}>
                          {inventoryLog.item_id}
                        </a>
                      </td>
                      <td
                        className={
                          inventoryLog.operation == "REMOVED"
                            ? "item-red"
                            : "item-green"
                        }
                      >
                        {inventoryLog.item_quantity}
                      </td>
                      <td
                        className={
                          inventoryLog.operation == "REMOVED"
                            ? "item-red"
                            : "item-green"
                        }
                      >
                        {inventoryLog.operation}
                      </td>
                      <td>
                        {DateTimeUtils.formatDateTime(
                          inventoryLog.date_published
                        )}
                      </td>
                      <td>{inventoryLog.created_by}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {searchParams.item_count > 0 &&
            searchParams.item_count < searchParams.expected_item_count && (
              <div className="load-more-purchase-orders-button-container">
                <Button
                  variant="outline-primary"
                  className="load-more-purchase-orders-button"
                  onClick={() => loadMore()}
                >
                  Load More..
                </Button>
              </div>
            )}
        </div>
      ) : (
        <div className="purchase-orders-table-container no-item">
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <div>No in out items were found.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default InaAndOutReports;
