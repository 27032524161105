import React, { useEffect, useState } from "react";
import {
  ImsInvoice,
  ImsDeliveryChallan,
  Vendor,
  ImsRecievableChallan,
  ImsAuditChallan,
} from "../../atomic_components/types";
import { Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import Datetime from "react-datetime";
import DatePicker from "react-modern-calendar-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./CSS/ChallanCreate.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";

import { set } from "lodash";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import DateTimeUtils from "../../utils/DateTimeUtils";
import ChallanNetworkUtils from "./NetworkUtils/ChallanNetworkUtils";
import { useHistory, useLocation } from "react-router-dom";
import CustomDatePicker from "../Invoices/CustomDatePicker";
import ChallanCustomDatePicker from "./ChallanCustomDatePicker";
import _ from "lodash";

enum ChallanType {
  RECIEVABLE = "RECIEVABLE",
  DELIVERY = "DELIVERY",
  AUDIT = "AUDIT",
}

function ChallanCreate() {
  const history = useHistory();
  const location = useLocation();

  const challanNetworkUtil = new ChallanNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const [vendorsList, setVendorsList] = useState<Vendor[]>([]);

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);

  const [selectedChallanType, setSelectedChallanType] = useState<string>(
    ChallanType.RECIEVABLE
  );

  const [deliveryChallan, setDeliveryChallan] = useState<ImsDeliveryChallan>({
    id: 0,
    buyer_name: "",
    buyer_contact_number: "",
    buyer_billing_address: "",
    buyer_shipping_address: "",
    buyer_gstin: "",
    challan_status: "",
    challan_date: "",
    item_count: 0,
    total_quantity: 0,
    created_by: "",
    date_published: "",
    created_at: "",
    updated_at: "",
  });

  const [recievableChallan, setRecievableChallan] =
    useState<ImsRecievableChallan>({
      id: 0,
      vendor_challan_number: "",
      vendor_name: "",
      vendor_contact_number: "",
      vendor_address: "",
      vendor_gstin: "",
      challan_status: "",
      challan_date: "",
      item_count: 0,
      total_quantity: 0,
      img_link1: "",
      img_link2: "",
      created_by: "",
      date_published: "",
      created_at: "",
      updated_at: "",
    });

  const [auditChallan, setAuditChallan] = useState<ImsAuditChallan>({
    id: 0,
    audit_reason: "",
    item_count: 0,
    total_quantity: 0,
    created_by: "",
    challan_status: "",
    date_published: "",
    created_at: "",
    updated_at: "",
  });

  const [selectedImage, setSelectedImage] = useState("img_main");
  const [uploadedImages, setUploadedImages] = useState<any>({
    img_link1: "http://tech.intellozene.com/Media/na.png",
    img_link2: "http://tech.intellozene.com/Media/na.png",
  });

  const [uploadedImageFiles, setUploadedImageFiles] = useState<any>({
    img_link1: null, // Initially set to null or empty
    img_link2: null,
  });

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  const handleImageError = (imageKey: string) => {
    // const updatedListing = { ...localListing };
    // updatedListing[imageKey] = "http://tech.intellozene.com/Media/na.png";
    // setLocalListing(updatedListing);
  };

  // Event handler to update the state when the input value changes

  const handleRecievableChallanChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setRecievableChallan({
      ...recievableChallan,
      [name]: value,
    });
  };

  const handleAuditChallanChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setAuditChallan({
      ...auditChallan,
      [name]: value,
    });
  };

  const handleDeliveryChallanChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setDeliveryChallan({
      ...deliveryChallan,
      [name]: value,
    });
  };

  const handleDropDownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedChallanType(event.target.value);
    // const { name, value } = event.target;
    // console.log(name, value);
    // setInvoice({
    //   ...invoice,
    //   [name]: value,
    // });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files).slice(0, 2); // Limit to 2 images
      // const newImages = { ...uploadedImages };
      const newImages: any = {
        img_link1: "http://tech.intellozene.com/Media/na.png",
        img_link2: "http://tech.intellozene.com/Media/na.png",
      };
      const newImageFiles: any = {};
      let loadedFilesCount = 0;

      fileArray.forEach((file, index) => {
        const imageKey = `img_link${index + 1}`;
        const reader = new FileReader();
        newImageFiles[imageKey] = file;

        reader.onload = (e) => {
          if (e.target) {
            newImages[imageKey] = e.target.result; // Set the image URL
            loadedFilesCount += 1;
            // setUploadedImages({ ...newImages });

            if (loadedFilesCount === fileArray.length) {
              setUploadedImageFiles(newImageFiles);
              setUploadedImages(newImages);
            }
          }
        };
        console.log(newImages);

        reader.readAsDataURL(file);
      });

      // setUploadedImageFiles(newImageFiles);
      // setUploadedImages(newImages);
    }
  };

  const [providedSelectedValue, setProvidedSelectedValue] =
    useState<string>("");

  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);

  const handleRecievableDateTimeChange = (newDateTime: Date | null) => {
    if (newDateTime) setSelectedDateTime(newDateTime);
  };

  const checkAndProceedForAuditChallan = async () => {
    let error_count = 0;
    if (!auditChallan.audit_reason || auditChallan.audit_reason.length < 2) {
      toast.error("Provide a valid audit reason.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (error_count == 0) {
      setShowLoadingPage(true);
      try {
        const body = {
          audit_reason: auditChallan.audit_reason,
          challan_status: "PENDING",
          created_by: userDetailsFromStore.user_details.userId,
        };
        const response = await challanNetworkUtil.createNewAuditChallan(body);
        const createdChallan: ImsAuditChallan = response.data;
        setShowLoadingPage(false);
        history.push(`/challans/create/audit/addItems/${createdChallan.id}`);
      } catch (error) {
        console.error("Error in creating invoice :", error);
      }
    }
  };

  const checkAndProceedForDeliveryChallan = async () => {
    let error_count = 0;
    if (!deliveryChallan.buyer_name || deliveryChallan.buyer_name.length < 5) {
      toast.error("Provide a valid buyer name.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (!selectedDateTime) {
      toast.error("Challan date not selected", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (
      !deliveryChallan.buyer_contact_number ||
      deliveryChallan.buyer_contact_number.length < 10
    ) {
      toast.error(
        "Invalid buyer contact number provided, It must have at least 10 digits.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
      error_count++;
    }

    if (
      !deliveryChallan.buyer_billing_address ||
      deliveryChallan.buyer_billing_address.length < 10
    ) {
      toast.error("Provide a valid billing address.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }
    if (
      !deliveryChallan.buyer_shipping_address ||
      deliveryChallan.buyer_shipping_address.length < 10
    ) {
      toast.error("Provide a valid shipping address.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (error_count == 0) {
      setShowLoadingPage(true);
      try {
        const body = {
          buyer_name: deliveryChallan.buyer_name,
          buyer_contact_number: deliveryChallan.buyer_contact_number,
          buyer_billing_address: deliveryChallan.buyer_billing_address,
          buyer_shipping_address: deliveryChallan.buyer_shipping_address,
          buyer_gstin: deliveryChallan.buyer_gstin,
          challan_status: "PENDING",
          challan_date: selectedDateTime
            ? DateTimeUtils.formatDateTimeForDb(selectedDateTime)
            : "",
          created_by: userDetailsFromStore.user_details.userId,
        };
        const response = await challanNetworkUtil.createNewDeliveryChallan(
          body
        );
        const createdChallan: ImsDeliveryChallan = response.data;
        setShowLoadingPage(false);
        history.push(`/challans/create/delivery/addItems/${createdChallan.id}`);
      } catch (error) {
        console.error("Error in creating invoice :", error);
      }
    }
  };

  const checkAndProceedForRecievableChallan = async () => {
    let error_count = 0;
    if (
      !recievableChallan.vendor_name ||
      recievableChallan.vendor_name.length < 2
    ) {
      toast.error("Provide a valid vendor name.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (
      !recievableChallan.vendor_challan_number ||
      recievableChallan.vendor_challan_number.length < 2
    ) {
      toast.error("Provide a valid vendor challan number.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (!selectedDateTime) {
      toast.error("Challan date not selected", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    if (
      !recievableChallan.vendor_contact_number ||
      recievableChallan.vendor_contact_number.length < 10
    ) {
      toast.error(
        "Invalid buyer contact number provided, It must have at least 10 digits.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
      error_count++;
    }

    if (
      !recievableChallan.vendor_address ||
      recievableChallan.vendor_address.length < 2
    ) {
      toast.error("Provide a valid billing address.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      error_count++;
    }

    let areImagesSet: boolean = false;
    Object.keys(uploadedImageFiles).forEach((key, index) => {
      if (uploadedImageFiles[key]) {
        areImagesSet = true;
      }
    });

    if (!areImagesSet) {
      toast.error(
        `Upload at least 1 chllan image in order to recieve challan.`,
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
      return;
    }

    const transferListing: ImsRecievableChallan =
      _.cloneDeep(recievableChallan);

    if (error_count == 0) {
      setShowLoadingPage(true);

      //uploading images first;
      try {
        const response = await challanNetworkUtil.uploadChallanImages(
          uploadedImageFiles
        );
        console.log(response);
        toast.success("Images successfully uploaded!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        updateTransferListing(response.data["paths"], transferListing);
      } catch (error) {
        console.log(error);
        toast.error(
          "Unknown error while trying to upload images! Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );

        return;
      }

      try {
        const body = {
          vendor_challan_number: recievableChallan.vendor_challan_number,
          vendor_name: recievableChallan.vendor_name,
          vendor_contact_number: recievableChallan.vendor_contact_number,
          vendor_address: recievableChallan.vendor_address,
          vendor_gstin: recievableChallan.vendor_gstin,
          challan_status: "PENDING",
          challan_date: selectedDateTime
            ? DateTimeUtils.formatDateTimeForDb(selectedDateTime)
            : "",
          created_by: userDetailsFromStore.user_details.userId,
          img_link1: transferListing.img_link1,
          img_link2: transferListing.img_link2,
        };
        const response = await challanNetworkUtil.createNewRecievableChallan(
          body
        );
        const createdChallan: ImsRecievableChallan = response.data;
        setShowLoadingPage(false);
        history.push(
          `/challans/create/recievable/addItems/${createdChallan.id}`
        );
      } catch (error) {
        console.error("Error in creating invoice :", error);
      }
    }
  };

  const updateTransferListing = (
    paths: any[],
    transferListing: ImsRecievableChallan
  ) => {
    transferListing.img_link1 = "http://tech.intellozene.com/Media/na.png";
    transferListing.img_link2 = "http://tech.intellozene.com/Media/na.png";

    paths.forEach((image, index) => {
      switch (index) {
        case 0:
          transferListing.img_link1 = image;
          break;
        case 1:
          transferListing.img_link2 = image;
          break;
        default:
          break;
      }
    });
  };

  const createChallanAndProceed = async () => {
    if (selectedChallanType == ChallanType.AUDIT) {
      checkAndProceedForAuditChallan();
    } else if (selectedChallanType == ChallanType.DELIVERY) {
      checkAndProceedForDeliveryChallan();
    } else if (selectedChallanType == ChallanType.RECIEVABLE) {
      checkAndProceedForRecievableChallan();
    }
  };

  // const createInvoiceAndProceed = async () => {
  //   // history.push(`/purchase-orders/add/addItems`);
  //   let error_count = 0;
  //   if (!selectedDateTimeselectedDateTime) {
  //     toast.error("Invoice date not selected", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //     });
  //     error_count++;
  //   }

  //   if (!invoice.customer_name || invoice.customer_name.length < 5) {
  //     toast.error("Valid customer name is required", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //     });
  //     error_count++;
  //   }

  //   if (
  //     !invoice.customer_contact_number ||
  //     invoice.customer_contact_number.length < 10
  //   ) {
  //     toast.error(
  //       "Invalid customer number provided, It must have at least 10 digits.",
  //       {
  //         position: toast.POSITION.TOP_RIGHT,
  //         hideProgressBar: true,
  //       }
  //     );
  //     error_count++;
  //   }

  //   if (
  //     !invoice.customer_billing_address ||
  //     invoice.customer_billing_address.length < 10
  //   ) {
  //     toast.error("Provide a valid billing address.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //     });
  //     error_count++;
  //   }
  //   if (
  //     !invoice.customer_shipping_address ||
  //     invoice.customer_shipping_address.length < 10
  //   ) {
  //     toast.error("Provide a valid shipping address.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //     });
  //     error_count++;
  //   }

  //   if (!invoice.payment_mode) {
  //     toast.error("Select an appropriate payment_mode.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true,
  //     });
  //     error_count++;
  //   }

  //   if (error_count > 0) return;
  //   setShowLoadingPage(true);
  //   const body = {
  //     customer_name: invoice.customer_name,
  //     customer_contact_number: invoice.customer_contact_number,
  //     customer_billing_address: invoice.customer_billing_address,
  //     customer_shipping_address: invoice.customer_shipping_address,
  //     customer_gstin: invoice.customer_gstin,
  //     payment_mode: invoice.payment_mode,
  //     invoice_status: "PENDING",
  //     invoice_date: selectedDateTime
  //       ? DateTimeUtils.formatDateTimeForDb(selectedDateTime)
  //       : "",
  //     created_by: userDetailsFromStore.user_details.userId,
  //   };
  //   try {
  //     const response = await challanNetworkUtil.createNewInvoice(body);
  //     const createdInvoice: ImsInvoice = response.data;
  //     setShowLoadingPage(false);
  //     history.push(`/invoices/add/addItems/${createdInvoice.id}`);
  //   } catch (error) {
  //     console.error("Error in creating invoice :", error);
  //   }
  // };

  return (
    <div className="challan-container mt-4">
      <div className="challan-form-container">
        <div className="challan-main-form">
          <div className="date-time-picker-container-main mb-3">
            <div className="date-time-picker-selected-date-container ml-1 mb-1">
              <span>Select Challan Type: </span> &nbsp;
            </div>
            <div className="date-time-picker-container">
              <select
                id="payment"
                value={selectedChallanType}
                name="payment_mode"
                className="date-time-picker-select"
                onChange={handleDropDownChange}
              >
                <option value={ChallanType.RECIEVABLE}>
                  {ChallanType.RECIEVABLE}
                </option>
                <option value={ChallanType.AUDIT}>{ChallanType.AUDIT}</option>
                <option value={ChallanType.DELIVERY}>
                  {ChallanType.DELIVERY}
                </option>
              </select>
            </div>
          </div>

          {selectedChallanType === "RECIEVABLE" ? (
            <div>
              <FloatingLabel
                controlId="floatingInput"
                label="Vendor Challan Number"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="vendor_challan_number"
                  value={recievableChallan.vendor_challan_number}
                  onChange={handleRecievableChallanChange}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Vendor Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="vendor_name"
                  value={recievableChallan.vendor_name}
                  onChange={handleRecievableChallanChange}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Vendor Contact Number"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="vendor_contact_number"
                  value={recievableChallan.vendor_contact_number}
                  onChange={handleRecievableChallanChange}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="Vendor Address"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="vendor_address"
                  value={recievableChallan.vendor_address}
                  onChange={handleRecievableChallanChange}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="Vendor GSTIN"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="vendor_gstin"
                  value={recievableChallan.vendor_gstin}
                  onChange={handleRecievableChallanChange}
                />
              </FloatingLabel>

              <div>
                <ChallanCustomDatePicker
                  onDateTimeChange={handleRecievableDateTimeChange}
                />
              </div>

              <div className="image-container-challan mt-4">
                <div className="image-list-add-new-item">
                  {["img_link1", "img_link2"].map((imageKey: string) => (
                    <div
                      key={imageKey}
                      className={`image-placeholder-add-item ${
                        selectedImage === imageKey ? "active" : ""
                      }`}
                      onClick={() => handleImageClick(imageKey)}
                    >
                      <img
                        src={
                          uploadedImages[imageKey]
                            ? uploadedImages[imageKey].toString()
                            : "NA"
                        }
                        alt={`Image ${imageKey}`}
                        onError={() => handleImageError(imageKey)}
                      />
                    </div>
                  ))}
                </div>

                <div className="details-button-container mt-2">
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }} // Hide the input field
                    id="imageUpload"
                  />
                  <label htmlFor="imageUpload" className="btn btn-primary">
                    Browse Images
                  </label>
                </div>
              </div>
            </div>
          ) : selectedChallanType === "AUDIT" ? (
            <div>
              {/* Render your AUDIT-related fields here */}

              <FloatingLabel
                controlId="floatingAuditInput"
                label="Audit Reason"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="audit_reason"
                  value={auditChallan.audit_reason}
                  onChange={handleAuditChallanChange}
                />
              </FloatingLabel>
              {/* Add more fields as needed */}
            </div>
          ) : (
            <div>
              {/* Render fallback or empty content */}
              <FloatingLabel
                controlId="floatingInput"
                label="Buyer Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="buyer_name"
                  value={deliveryChallan.buyer_name}
                  onChange={handleDeliveryChallanChange}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Buyer Contact Number"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="buyer_contact_number"
                  value={deliveryChallan.buyer_contact_number}
                  onChange={handleDeliveryChallanChange}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Buyer Billing Address"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="buyer_billing_address"
                  value={deliveryChallan.buyer_billing_address}
                  onChange={handleDeliveryChallanChange}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Buyer Shipping Address"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="buyer_shipping_address"
                  value={deliveryChallan.buyer_shipping_address}
                  onChange={handleDeliveryChallanChange}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Buyer GSTIN"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="buyer_gstin"
                  value={deliveryChallan.buyer_gstin}
                  onChange={handleDeliveryChallanChange}
                />
              </FloatingLabel>

              <div>
                <ChallanCustomDatePicker
                  onDateTimeChange={handleRecievableDateTimeChange}
                />
              </div>
            </div>
          )}

          <div className="purchase-order-create-button-container mt-4">
            <Button
              className="purchase-order-create-button"
              variant="outline-primary"
              onClick={createChallanAndProceed}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Creating challan, please wait...
        </div>
      )}
    </div>
  );
}

export default ChallanCreate;
