import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import "./CSS/ChallanAll.css";
import {
  ImsAuditChallan,
  ImsInvoice,
  PurchaseOrder,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { Prev } from "react-bootstrap/esm/PageItem";
import ChallanNetworkUtils from "./NetworkUtils/ChallanNetworkUtils";
import InvoicesSearchBar from "./ChallanSearchBar";

function ChallanAudit() {
  const history = useHistory();
  const location = useLocation();
  const challanNetworkUtils = new ChallanNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [allChallans, setAllChallans] = useState<ImsAuditChallan[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const getAuditChallansPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response = await challanNetworkUtils.getAuditChallansPaginated(
        fromHandleSearch == true ? searchString : searchParams.search_string,
        pageNumber,
        40
      );
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setAllChallans((prevList) => [...prevList, ...response.data["content"]]);

      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetching audit challans :", error);
      setIsLoading(false);
    }
  };

  const handleSearch = (query: string) => {
    setAllChallans([]);
    setIsLoading(true);

    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    getAuditChallansPaginated(query, 1, true);
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getAuditChallansPaginated("", searchParams.current_page_num + 1, false);
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  useEffect(() => {
    getAuditChallansPaginated("", 1, false);
  }, []);

  useEffect(() => {
    console.log(searchParams);
  }, [searchParams]);

  const getChallanStatus = (poStatus: string) => {
    if (poStatus == "PENDING") return "Pending";
    else if (poStatus == "PUBLISHED") return "Published";
    return "NA";
  };

  return (
    <div className="purchase-orders-container">
      <div className="purchase-orders-search-bar-container">
        <div className="colors-overview-container">
          {/* <ColorCard color="red" />
          <ColorCard color="orange" /> */}
          {/* <ColorCard color="green" /> */}
        </div>
        <InvoicesSearchBar onSearch={handleSearch} />
      </div>
      {allChallans.length > 0 ? (
        <div className="purchase-orders-table-container">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Challan Id</th>
                <th>Audit Reason</th>
                <th>Challan Status</th>
                <th>Item Count</th>
                <th>Total Quantity</th>
                <th>Date Challan Published</th>
                <th>Last Updated</th>
                <th>Date Created</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {allChallans.map((challan, index) => {
                return (
                  <tr className="purchase-orders-search-bar-row" key={index}>
                    <td className="invoice-id-link">
                      <a
                        href={
                          challan.challan_status == "PENDING"
                            ? `/challans/create/audit/addItems/${challan.id}`
                            : `/challans/published/audit/${challan.id}`
                        }
                      >
                        {challan.id}
                      </a>
                    </td>
                    <td>{challan.audit_reason}</td>
                    <td>{getChallanStatus(challan.challan_status)}</td>
                    <td>{challan.item_count}</td>
                    <td>{challan.total_quantity}</td>
                    <td>
                      {challan.challan_status == "PENDING"
                        ? ""
                        : DateTimeUtils.formatDateTime(challan.date_published)}
                    </td>
                    <td>{DateTimeUtils.formatDateTime(challan.updated_at)}</td>
                    <td>{DateTimeUtils.formatDateTime(challan.created_at)}</td>
                    <td>{challan.created_by}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {searchParams.item_count > 0 &&
            searchParams.item_count < searchParams.expected_item_count && (
              <div className="load-more-purchase-orders-button-container">
                <Button
                  variant="outline-primary"
                  className="load-more-purchase-orders-button"
                  onClick={() => loadMore()}
                >
                  Load More..
                </Button>
              </div>
            )}
        </div>
      ) : (
        <div className="purchase-orders-table-container no-item">
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <div>No Challans were found.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default ChallanAudit;
