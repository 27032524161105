import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/InvoicesAddItem.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";

import {
  ErrorMessageBody,
  ImsInvoice,
  ImsInvoiceItem,
  PoItem,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemWithLisiting,
  Specialty,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";

import Header from "../Header";
import VendorListingsView from "../Vendors/VendorListingsView";
import VendorsListingSearchBar from "../Vendors/VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import InvoicesPublishedItemsView from "./InvoicesPublishedItemsView";
import InvoicesPDF from "./InvoicesPDF";
import InvoiceNetworkUtils from "./NetworkUtils/InvoiceNetworkUtils";

interface RouteParams {
  invoice_id: string;
}

function InvoicesPublished() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const { invoice_id } = useParams<RouteParams>();

  // const [currentInvoice, setcurrentInvoice] =
  //   useState<PurchaseOrder | null>(null);
  const [currentInvoice, setCurrentInvoice] = useState<ImsInvoice | null>(null);
  const [modalShow, setModalShow] = useState(false);
  const [modifyPurchaseOrder, setModifyPurchaseOrder] = useState(false);
  const [cancelPurchaseOrder, setCancelPurchaseOrder] = useState(false);
  // const [chunkedInvoiceItems, setChunkedInvoiceItems] = useState<
  //   ImsInvoiceItem[][]
  // >([]);
  const [invoiceItemsWithListing, setInvoiceItemsWithListing] = useState<
    ImsInvoiceItem[]
  >([]);
  const [filteredInvoiceItemsWithListing, setFilteredInvoiceItemsWithListing] =
    useState<ImsInvoiceItem[]>([]);

  const [generatePDF, setGeneratePDF] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const invoiceNetworkUtil = new InvoiceNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  useEffect(() => {
    const fetchInvoiceByInvoiceId = async (invoiceId: string) => {
      console.log(invoiceId);
      try {
        const response = await invoiceNetworkUtil.getInvoiceByInvoiceId(
          invoiceId
        );
        console.log(response.data);
        setCurrentInvoice(response.data);
      } catch (error) {
        console.error("Error in fetching invoice by invoice id :", error);
      }
    };

    fetchInvoiceByInvoiceId(invoice_id);
  }, [invoice_id]);

  useEffect(() => {
    const getInvoiceItemsWithListingByInvoiceId = async (invoiceId: number) => {
      try {
        const response = await invoiceNetworkUtil.getInvoiceItemsWithListing(
          invoiceId.toString()
        );

        console.log(response.data);
        setInvoiceItemsWithListing(response.data);
        setFilteredInvoiceItemsWithListing(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error in getting invoice items by invoice id : ", error);
        setIsLoading(false);
      }
    };

    if (currentInvoice) {
      if (currentInvoice.invoice_status == "PENDING") {
        history.push(`/invoices/add/addItems/${invoice_id}`);
      }
      getInvoiceItemsWithListingByInvoiceId(currentInvoice.id);
    }
  }, [currentInvoice]);

  // useEffect(() => {
  //   setChunkedInvoiceItems(chunkArray(invoiceItemsWithListing, 4));
  // }, [invoiceItemsWithListing]);

  const chunkArray = (
    array: ImsInvoiceItem[],
    size: number
  ): ImsInvoiceItem[][] => {
    return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
      array.slice(index * size, index * size + size)
    );
  };

  const handleSearch = (query: string) => {
    console.log("invoice item searched ", query);
    if (query.length == 0)
      setFilteredInvoiceItemsWithListing(_.cloneDeep(invoiceItemsWithListing));
    else
      setFilteredInvoiceItemsWithListing(filterInvoiceItemsWithListings(query));
  };

  const filterInvoiceItemsWithListings = (query: string) => {
    query = query.toLowerCase();
    return invoiceItemsWithListing.filter((invoiceItem) => {
      const skuMatches = invoiceItem.listing.item_id
        .toLowerCase()
        .includes(query);
      const descriptionMatches = invoiceItem.listing.item_description
        .toLowerCase()
        .includes(query);
      return skuMatches || descriptionMatches;
    });
  };

  const onCardClick = (currentListing: ImsInvoiceItem) => {
    console.log("card clicked: ", currentListing);
    setModalShow(true);
    // setSelectedListing(currentListing);
  };

  const markForModification = async () => {
    try {
      const response = await invoiceNetworkUtil.markPoForModification(
        invoice_id
      );
      history.push(`/purchase-orders/add/addItems/${invoice_id}`);
      console.log(response.data);
    } catch (error) {
      console.error(
        "Error in marking purchase order for modification : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const markForCancellation = async () => {
    try {
      const response = await invoiceNetworkUtil.markPoForCancellation(
        invoice_id
      );
      setCurrentInvoice(response.data);
      toast.success("Cancelled PO successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setCancelPurchaseOrder(false);
    } catch (error) {
      console.error(
        "Error in marking purchase order for cancellation : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  return (
    <div className="overlay">
      <div className="vendor-details">
        <Header />
        <div className="main-container">
          {invoiceItemsWithListing.length > 0 && currentInvoice ? (
            <div className="vendor-action-container">
              {currentInvoice.invoice_status != "CANCELLED" && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Invoice Actions</h4>
                  </div>

                  <div className="vendor-action-buttons-container">
                    {generatePDF
                      ? currentInvoice &&
                        invoiceItemsWithListing.length > 0 && (
                          <div>
                            <PDFDownloadLink
                              document={
                                <InvoicesPDF
                                  invoiceItems={invoiceItemsWithListing}
                                  invoice={currentInvoice}
                                  totalItems={invoiceItemsWithListing.length}
                                  totalQuantity={invoiceItemsWithListing.reduce(
                                    (total, invoiceItem) =>
                                      total + invoiceItem.item_quantity,
                                    0
                                  )}
                                />
                              }
                              fileName={currentInvoice.id.toString()}
                            >
                              {({ blob, url, loading, error }) => (
                                <Button
                                  className="vendor-action-button"
                                  disabled={loading}
                                >
                                  {loading
                                    ? "Loading document..."
                                    : "Download Invoice PDF"}
                                </Button>
                              )}
                            </PDFDownloadLink>
                          </div>
                        )
                      : currentInvoice.invoice_status != "DELETED" &&
                        currentInvoice.invoice_status != "CANCELLED" && (
                          <Button
                            variant="outline-primary"
                            className="vendor-action-button"
                            onClick={() => setGeneratePDF(true)}
                          >
                            Generate Invoice PDF
                          </Button>
                        )}

                    {/* {currentInvoice.status != "MARKED_COMPLETED" &&
                      currentInvoice.status != "MARKED_CANCELLED" && (
                        <Button
                          variant="outline-primary"
                          className="vendor-action-button"
                          onClick={() => setModifyPurchaseOrder(true)}
                        >
                          Modify Purchase Order
                        </Button>
                      )}
                    {currentInvoice.status != "MARKED_COMPLETED" &&
                      currentInvoice.status != "MARKED_CANCELLED" && (
                        <Button
                          variant="outline-primary"
                          className="vendor-action-button"
                          onClick={() => setCancelPurchaseOrder(true)}
                        >
                          Cancel Purchase Order
                        </Button>
                      )} */}
                    {/* {currentInvoice.status != "MARKED_COMPLETED" &&
                      currentInvoice.status != "MARKED_CANCELLED" && (
                        <Button
                          variant="outline-primary"
                          className="vendor-action-button"
                        >
                          Start Receiving Items against this Purchase Order
                        </Button>
                      )} */}
                  </div>
                </div>
              )}
              {invoiceItemsWithListing.length > 0 && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Published Invoice Details</h4>
                    <div className="vendor-details-button-container"></div>
                  </div>
                  <div className="grid-1-cells">
                    {invoiceItemsWithListing.length != 0 && (
                      <div className="grid-2-cells">
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total SKUs : </b>{" "}
                            {invoiceItemsWithListing.length.toString()}
                          </div>
                        </Alert>
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Quantity : </b>
                            {invoiceItemsWithListing.reduce(
                              (total, invoiceItem) =>
                                total + invoiceItem.item_quantity,
                              0
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {currentInvoice && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Invoice Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Invoice status</label>
                      {currentInvoice.invoice_status == "PUBLISHED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="primary">
                          PUBLISHED
                        </Badge>
                      )}
                      {currentInvoice.invoice_status == "CANCELLED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="dark">
                          CANCELLED
                        </Badge>
                      )}
                      {currentInvoice.invoice_status == "DELETED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="danger">
                          DELETED
                        </Badge>
                      )}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Invoice Id</label>
                      <input
                        type="text"
                        name="invoice_id"
                        value={currentInvoice.id}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Customer Name</label>
                      <input
                        type="text"
                        name="customer_name"
                        value={currentInvoice.customer_name}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Customer Contact Number</label>
                      <input
                        type="text"
                        name="customer_contact_number"
                        value={currentInvoice.customer_contact_number}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Customer Billing Address</label>
                      <input
                        type="text"
                        name="customer_billing_address"
                        value={currentInvoice.customer_billing_address}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Customer Shipping Address</label>
                      <input
                        type="text"
                        name="customer_shipping_address"
                        value={currentInvoice.customer_shipping_address}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Customer GSTIN</label>
                      <input
                        type="text"
                        name="customer_gstin"
                        value={currentInvoice.customer_gstin}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Invoice Date</label>
                      <input
                        type="text"
                        name="invoice_date"
                        value={DateTimeUtils.formatDateOnly(
                          currentInvoice.invoice_date
                        )}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="po_created_by"
                        value={currentInvoice.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="po_date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentInvoice.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="vendor-action-container"></div>
          )}
          {isLoading ? (
            <div className="vendor-skus-spinner-container">
              {" "}
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="invoice-published-items-container">
              {invoiceItemsWithListing.length > 0 && currentInvoice && (
                <div className="invoice-published-listings-search-bar-container">
                  <VendorsListingSearchBar
                    onSearch={handleSearch}
                  ></VendorsListingSearchBar>
                </div>
              )}

              <div className="invoice-published-items-only-container">
                <InvoicesPublishedItemsView
                  publishedListings={filteredInvoiceItemsWithListing}
                  onCardClick={onCardClick}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={modifyPurchaseOrder}
        onHide={() => setModifyPurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modify Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure about modification? Please ensure to inform vendor about
          the updated purchase order.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModifyPurchaseOrder(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => markForModification()}>
            Understood
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cancelPurchaseOrder}
        onHide={() => setCancelPurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this Purchase Order? Please note that
          purchase orders whose status is "Published" and against which no
          quantities were received can only be marked "Cancelled".
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setCancelPurchaseOrder(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => markForCancellation()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default InvoicesPublished;
