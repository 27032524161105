import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/ChallanAddItems.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";

import {
  ErrorMessageBody,
  ImsAuditChallan,
  ImsChallanItem,
  ImsDeliveryChallan,
  ImsInvoice,
  ImsInvoiceItem,
  ImsRecievableChallan,
  PoItem,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemWithLisiting,
  Specialty,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";

import Header from "../Header";
import VendorListingsView from "../Vendors/VendorListingsView";
import VendorsListingSearchBar from "../Vendors/VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import InvoicesPublishedItemsView from "./ChallanPublishedItemsView";
import ChallanDeliveryPDF from "./ChallanDeliveryPDF";
import ChallanNetworkUtils from "./NetworkUtils/ChallanNetworkUtils";
import ChallanPublishedItemsView from "./ChallanPublishedItemsView";
import ChallanAuditPDF from "./ChallanAuditPDF";

interface RouteParams {
  type: string;
  challan_id: string;
}

function ChallanPublished() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const { type, challan_id } = useParams<RouteParams>();

  // const [currentRecievableChallan, setcurrentRecievableChallan] =
  //   useState<PurchaseOrder | null>(null);
  const [currentRecievableChallan, setCurrentRecievableChallan] =
    useState<ImsRecievableChallan | null>(null);
  const [currentDeliveryChallan, setCurrentDeliveryChallan] =
    useState<ImsDeliveryChallan | null>(null);
  const [currentAuditChallan, setCurrentAuditChallan] =
    useState<ImsAuditChallan | null>(null);

  const [challanItemWithListing, setChallantemsWithListing] = useState<
    ImsChallanItem[]
  >([]);
  const [filteredChallanItemsWithListing, setFilteredChallanItemsWithListing] =
    useState<ImsChallanItem[]>([]);

  const [generatePDF, setGeneratePDF] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const challanNetworkUtil = new ChallanNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  useEffect(() => {
    const getAuditChallanByChallanId = async (invoiceId: string) => {
      try {
        const response = await challanNetworkUtil.getAuditChallanByChallanId(
          invoiceId
        );
        // console.log(response.data);
        setCurrentAuditChallan(response.data);
      } catch (error) {
        console.error("Error in fetching audit challan by challan ID", error);
      }
    };

    const getRecievableChallanByChallanId = async (invoiceId: string) => {
      try {
        const response =
          await challanNetworkUtil.getRecievableChallanByChallanId(invoiceId);
        // console.log(response.data);
        setCurrentRecievableChallan(response.data);
      } catch (error) {
        console.error(
          "Error in fetching recievable challan by challan ID",
          error
        );
      }
    };

    const getDeliveryChallanByChallanId = async (invoiceId: string) => {
      try {
        const response = await challanNetworkUtil.getDeliveryChallanByChallanId(
          invoiceId
        );
        // console.log(response.data);
        setCurrentDeliveryChallan(response.data);
      } catch (error) {
        console.error(
          "Error in fetching delivery challan by challan ID",
          error
        );
      }
    };

    if (type && challan_id) {
      setIsLoading(true);
      if (type == "audit") {
        getAuditChallanByChallanId(challan_id);
      } else if (type == "recievable") {
        getRecievableChallanByChallanId(challan_id);
      } else if (type == "delivery") {
        getDeliveryChallanByChallanId(challan_id);
      }
    }
  }, [type, challan_id]);

  useEffect(() => {
    const getAuditChallanItems = async (challanId: number) => {
      try {
        const response =
          await challanNetworkUtil.getAuditChallanItemsWithListing(
            challanId.toString()
          );
        // console.log(response.data);
        setChallantemsWithListing(response.data);
        setFilteredChallanItemsWithListing(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error in getting getAuditChallanItems : ", error);
      }
    };

    const getDeliveryChallanItems = async (challanId: number) => {
      try {
        const response =
          await challanNetworkUtil.getDeliveryChallanItemsWithListing(
            challanId.toString()
          );
        // console.log(response.data);
        setChallantemsWithListing(response.data);
        setFilteredChallanItemsWithListing(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error in getting getDeliveryChallanItems : ", error);
      }
    };

    const getRecievableChallanItems = async (challanId: number) => {
      try {
        const response =
          await challanNetworkUtil.getRecievableChallanItemsWithListing(
            challanId.toString()
          );
        // console.log(response.data);
        setChallantemsWithListing(response.data);
        setFilteredChallanItemsWithListing(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error in getting getDeliveryChallanItems : ", error);
      }
    };

    if (currentAuditChallan) {
      if (currentAuditChallan.challan_status == "PENDING") {
        history.push(`/challans/create/audit/addItems/${challan_id}`);
      } else {
        getAuditChallanItems(currentAuditChallan.id);
      }
    } else if (currentDeliveryChallan) {
      if (currentDeliveryChallan.challan_status == "PENDING") {
        history.push(`/challans/create/delivery/addItems/${challan_id}`);
      } else {
        getDeliveryChallanItems(currentDeliveryChallan.id);
      }
    } else if (currentRecievableChallan) {
      if (currentRecievableChallan.challan_status == "PENDING") {
        history.push(`/challans/create/recievable/addItems/${challan_id}`);
      } else {
        getRecievableChallanItems(currentRecievableChallan.id);
      }
    }
  }, [currentAuditChallan, currentRecievableChallan, currentDeliveryChallan]);

  // useEffect(() => {
  //   setChunkedInvoiceItems(chunkArray(challanItemWithListing, 4));
  // }, [challanItemWithListing]);

  const chunkArray = (
    array: ImsInvoiceItem[],
    size: number
  ): ImsInvoiceItem[][] => {
    return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
      array.slice(index * size, index * size + size)
    );
  };

  const handleSearch = (query: string) => {
    console.log("invoice item searched ", query);
    if (query.length == 0)
      setFilteredChallanItemsWithListing(_.cloneDeep(challanItemWithListing));
    else
      setFilteredChallanItemsWithListing(filterChallanItemsWithListings(query));
  };

  const filterChallanItemsWithListings = (query: string) => {
    query = query.toLowerCase();
    return challanItemWithListing.filter((challanItem) => {
      const skuMatches = challanItem.listing.item_id
        .toLowerCase()
        .includes(query);
      const descriptionMatches = challanItem.listing.item_description
        .toLowerCase()
        .includes(query);
      return skuMatches || descriptionMatches;
    });
  };

  const onCardClick = (currentListing: ImsChallanItem) => {
    console.log("card clicked: ", currentListing);
  };

  return (
    <div className="overlay">
      <div className="vendor-details">
        <Header />
        <div className="main-container">
          {challanItemWithListing.length > 0 ? (
            <div className="vendor-action-container">
              {currentDeliveryChallan && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Delivery Challan Actions</h4>
                  </div>

                  <div className="vendor-action-buttons-container">
                    {generatePDF
                      ? currentDeliveryChallan &&
                        challanItemWithListing.length > 0 && (
                          <div>
                            <PDFDownloadLink
                              document={
                                <ChallanDeliveryPDF
                                  challanItems={challanItemWithListing}
                                  deliveryChallan={currentDeliveryChallan}
                                  totalItems={challanItemWithListing.length}
                                  totalQuantity={challanItemWithListing.reduce(
                                    (total, invoiceItem) =>
                                      total + invoiceItem.item_quantity,
                                    0
                                  )}
                                />
                              }
                              fileName={
                                currentDeliveryChallan.id.toString() +
                                "_delivery_challan"
                              }
                            >
                              {({ blob, url, loading, error }) => (
                                <Button
                                  className="vendor-action-button"
                                  disabled={loading}
                                >
                                  {loading
                                    ? "Loading document..."
                                    : "Download Delivery Challan PDF"}
                                </Button>
                              )}
                            </PDFDownloadLink>
                          </div>
                        )
                      : currentDeliveryChallan.challan_status != "DELETED" &&
                        currentDeliveryChallan.challan_status !=
                          "CANCELLED" && (
                          <Button
                            variant="outline-primary"
                            className="vendor-action-button"
                            onClick={() => setGeneratePDF(true)}
                          >
                            Generate Delivery Challan PDF
                          </Button>
                        )}
                  </div>
                </div>
              )}

              {currentAuditChallan && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Audit Challan Actions</h4>
                  </div>

                  <div className="vendor-action-buttons-container">
                    {generatePDF
                      ? currentAuditChallan &&
                        challanItemWithListing.length > 0 && (
                          <div>
                            <PDFDownloadLink
                              document={
                                <ChallanAuditPDF
                                  challanItems={challanItemWithListing}
                                  auditChallan={currentAuditChallan}
                                  totalItems={challanItemWithListing.length}
                                  totalQuantity={challanItemWithListing.reduce(
                                    (total, invoiceItem) =>
                                      total + invoiceItem.item_quantity,
                                    0
                                  )}
                                />
                              }
                              fileName={
                                currentAuditChallan.id.toString() +
                                "_audit_challan"
                              }
                            >
                              {({ blob, url, loading, error }) => (
                                <Button
                                  className="vendor-action-button"
                                  disabled={loading}
                                >
                                  {loading
                                    ? "Loading document..."
                                    : "Download Audit Challan PDF"}
                                </Button>
                              )}
                            </PDFDownloadLink>
                          </div>
                        )
                      : currentAuditChallan.challan_status != "DELETED" &&
                        currentAuditChallan.challan_status != "CANCELLED" && (
                          <Button
                            variant="outline-primary"
                            className="vendor-action-button"
                            onClick={() => setGeneratePDF(true)}
                          >
                            Generate Audit Challan PDF
                          </Button>
                        )}
                  </div>
                </div>
              )}

              {currentRecievableChallan && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Recievable Challan Images</h4>
                  </div>

                  <div className="image-list-add-new-item">
                    <div className={`image-placeholder-add-item`}>
                      <img src={currentRecievableChallan.img_link1} />
                    </div>

                    <div className={`image-placeholder-add-item`}>
                      <img src={currentRecievableChallan.img_link2} />
                    </div>
                  </div>
                </div>
              )}

              {challanItemWithListing.length > 0 && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Published Challan Details</h4>
                    <div className="vendor-details-button-container"></div>
                  </div>
                  <div className="grid-1-cells">
                    {challanItemWithListing.length != 0 && (
                      <div className="grid-2-cells">
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total SKUs : </b>{" "}
                            {challanItemWithListing.length.toString()}
                          </div>
                        </Alert>
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Quantity : </b>
                            {challanItemWithListing.reduce(
                              (total, invoiceItem) =>
                                total + invoiceItem.item_quantity,
                              0
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {currentRecievableChallan && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Challan Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Challan status</label>
                      {currentRecievableChallan.challan_status ==
                        "PUBLISHED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="primary">
                          PUBLISHED
                        </Badge>
                      )}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Challan Id</label>
                      <input
                        type="text"
                        name="invoice_id"
                        value={currentRecievableChallan.id}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Challan Number</label>
                      <input
                        type="text"
                        name="customer_name"
                        value={currentRecievableChallan.vendor_challan_number}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Name</label>
                      <input
                        type="text"
                        name="customer_name"
                        value={currentRecievableChallan.vendor_name}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Contact Number</label>
                      <input
                        type="text"
                        name="customer_contact_number"
                        value={currentRecievableChallan.vendor_contact_number}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Address</label>
                      <input
                        type="text"
                        name="customer_billing_address"
                        value={currentRecievableChallan.vendor_address}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor GSTIN</label>
                      <input
                        type="text"
                        name="customer_gstin"
                        value={currentRecievableChallan.vendor_gstin}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date On Challan</label>
                      <input
                        type="text"
                        name="invoice_date"
                        value={DateTimeUtils.formatDateOnly(
                          currentRecievableChallan.challan_date
                        )}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="po_created_by"
                        value={currentRecievableChallan.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="po_date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentRecievableChallan.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              {currentDeliveryChallan && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Challan Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Challan status</label>
                      {currentDeliveryChallan.challan_status == "PUBLISHED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="primary">
                          PUBLISHED
                        </Badge>
                      )}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Challan Id</label>
                      <input
                        type="text"
                        name="invoice_id"
                        value={currentDeliveryChallan.id}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Buyer Name</label>
                      <input
                        type="text"
                        name="customer_name"
                        value={currentDeliveryChallan.buyer_name}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Buyer Contact Number</label>
                      <input
                        type="text"
                        name="customer_contact_number"
                        value={currentDeliveryChallan.buyer_contact_number}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Buyer Billing Address</label>
                      <input
                        type="text"
                        name="customer_billing_address"
                        value={currentDeliveryChallan.buyer_billing_address}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Buyer Shipping Address</label>
                      <input
                        type="text"
                        name="customer_billing_address"
                        value={currentDeliveryChallan.buyer_shipping_address}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Buyer GSTIN</label>
                      <input
                        type="text"
                        name="customer_gstin"
                        value={currentDeliveryChallan.buyer_gstin}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date On Challan</label>
                      <input
                        type="text"
                        name="invoice_date"
                        value={DateTimeUtils.formatDateOnly(
                          currentDeliveryChallan.challan_date
                        )}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="po_created_by"
                        value={currentDeliveryChallan.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="po_date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentDeliveryChallan.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              {currentAuditChallan && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Challan Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Challan status</label>
                      {currentAuditChallan.challan_status == "PUBLISHED" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="primary">
                          PUBLISHED
                        </Badge>
                      )}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Challan Id</label>
                      <input
                        type="text"
                        name="invoice_id"
                        value={currentAuditChallan.id}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Audit Reason</label>
                      <input
                        type="text"
                        name="customer_name"
                        value={currentAuditChallan.audit_reason}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="po_created_by"
                        value={currentAuditChallan.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="po_date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentAuditChallan.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="vendor-action-container"></div>
          )}
          {isLoading ? (
            <div className="vendor-skus-spinner-container">
              {" "}
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="invoice-published-items-container">
              {challanItemWithListing.length > 0 &&
                (currentRecievableChallan ||
                  currentDeliveryChallan ||
                  currentAuditChallan) && (
                  <div className="invoice-published-listings-search-bar-container">
                    <VendorsListingSearchBar
                      onSearch={handleSearch}
                    ></VendorsListingSearchBar>
                  </div>
                )}

              <div className="invoice-published-items-only-container">
                <ChallanPublishedItemsView
                  publishedListings={filteredChallanItemsWithListing}
                  onCardClick={onCardClick}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChallanPublished;
