import React, { useEffect, useState, KeyboardEvent, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/ChallanAddItems.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";

import {
  ImsInvoice,
  ImsInvoiceItem,
  ImsListing,
  PoItem,
  PurchaseOrder,
  SearchParams,
  Specialty,
  Vendor,
  VendorListing,
  InvoiceError,
  ImsAuditChallan,
  ImsRecievableChallan,
  ImsDeliveryChallan,
  ImsChallanItem,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import Header from "../Header";
import VendorListingsView from "../Vendors/VendorListingsView";
import VendorsListingSearchBar from "../Vendors/VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
// import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import ChallanNetworkUtils from "./NetworkUtils/ChallanNetworkUtils";
import ChallanSearchBarAddItem from "./ChallanSearchBarAddItem";
import ChallanEmbeddedListingsView from "./ChallanEmbeddedListingsView";

interface RouteParams {
  type: string;
  operation: string;
  challan_id: string;
}

function ChallanAddItems() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const { type, operation, challan_id } = useParams<RouteParams>();
  const [selectedListing, setSelectedListing] = useState<ImsListing | null>(
    null
  );

  const [challanItems, setChallanItems] = useState<ImsChallanItem[]>([]);
  const [selectedChallanItem, setSelectedChallanItem] =
    useState<ImsChallanItem | null>(null);
  const [isItemPartOfChallan, setIsItemPartOfChallan] =
    useState<boolean>(false);

  const [currentAuditChallan, setCurrentAuditChallan] =
    useState<ImsAuditChallan | null>(null);
  const [currentRecievableChallan, setCurrentRecievableChallan] =
    useState<ImsRecievableChallan | null>(null);
  const [currentDeliveryChallan, setCurrentDeliveryChallan] =
    useState<ImsDeliveryChallan | null>(null);

  const [modalShow, setModalShow] = useState(false);
  const [emptyModalShow, setEmptyModalShow] = useState(false);
  const [listings, setListings] = useState<ImsListing[]>([]);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);

  const [requiredQuantity, setRequiredQuantity] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const challanNetworkUtil = new ChallanNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input field

  useEffect(() => {
    if (modalShow && inputRef.current) {
      inputRef.current.focus(); // Focus the input field when the modal opens
    }
  }, [modalShow]);

  useEffect(() => {
    const getAuditChallanByChallanId = async (invoiceId: string) => {
      try {
        const response = await challanNetworkUtil.getAuditChallanByChallanId(
          invoiceId
        );
        // console.log(response.data);
        setCurrentAuditChallan(response.data);
      } catch (error) {
        console.error("Error in fetching audit challan by challan ID", error);
      }
    };

    const getRecievableChallanByChallanId = async (invoiceId: string) => {
      try {
        const response =
          await challanNetworkUtil.getRecievableChallanByChallanId(invoiceId);
        // console.log(response.data);
        setCurrentRecievableChallan(response.data);
      } catch (error) {
        console.error(
          "Error in fetching recievable challan by challan ID",
          error
        );
      }
    };

    const getDeliveryChallanByChallanId = async (invoiceId: string) => {
      try {
        const response = await challanNetworkUtil.getDeliveryChallanByChallanId(
          invoiceId
        );
        // console.log(response.data);
        setCurrentDeliveryChallan(response.data);
      } catch (error) {
        console.error(
          "Error in fetching delivery challan by challan ID",
          error
        );
      }
    };

    if (type && challan_id) {
      if (type == "audit") {
        getAuditChallanByChallanId(challan_id);
      } else if (type == "recievable") {
        getRecievableChallanByChallanId(challan_id);
      } else if (type == "delivery") {
        getDeliveryChallanByChallanId(challan_id);
      }
    }
  }, [type, challan_id]);

  useEffect(() => {
    const getAuditChallanItems = async (challanId: number) => {
      try {
        const response = await challanNetworkUtil.getAuditChallanItems(
          challanId.toString()
        );
        // console.log(response.data);
        setChallanItems(response.data);
        setShowLoadingPage(false);
      } catch (error) {
        setShowLoadingPage(false);
        console.error("Error in getting getAuditChallanItems : ", error);
      }
    };

    const getDeliveryChallanItems = async (challanId: number) => {
      try {
        const response = await challanNetworkUtil.getDeliveryChallanItems(
          challanId.toString()
        );
        // console.log(response.data);
        setChallanItems(response.data);
        setShowLoadingPage(false);
      } catch (error) {
        setShowLoadingPage(false);
        console.error("Error in getting getDeliveryChallanItems : ", error);
      }
    };

    const getRecievableChallanItems = async (challanId: number) => {
      try {
        const response = await challanNetworkUtil.getRecievableChallanItems(
          challanId.toString()
        );
        // console.log(response.data);
        setChallanItems(response.data);
        setShowLoadingPage(false);
      } catch (error) {
        setShowLoadingPage(false);
        console.error("Error in getting getDeliveryChallanItems : ", error);
      }
    };

    if (currentAuditChallan) {
      if (currentAuditChallan.challan_status == "PUBLISHED") {
        history.push(`/challans/published/audit/${challan_id}`);
      } else {
        getAuditChallanItems(currentAuditChallan.id);
        handleSearch("");
      }
    } else if (currentDeliveryChallan) {
      if (currentDeliveryChallan.challan_status == "PUBLISHED") {
        history.push(`/challans/published/delivery/${challan_id}`);
      } else {
        getDeliveryChallanItems(currentDeliveryChallan.id);
        handleSearch("");
      }
    } else if (currentRecievableChallan) {
      if (currentRecievableChallan.challan_status == "PUBLISHED") {
        history.push(`/challans/published/recievable/${challan_id}`);
      } else {
        getRecievableChallanItems(currentRecievableChallan.id);
        handleSearch("");
      }
    }
  }, [currentAuditChallan, currentRecievableChallan, currentDeliveryChallan]);

  useEffect(() => {
    if (!modalShow) {
      setRequiredQuantity(0);
      setIsItemPartOfChallan(false);
      setSelectedListing(null);
      setSelectedChallanItem(null);
    }
  }, [modalShow]);

  useEffect(() => {
    let sku: string = "";
    if (selectedListing) {
      sku = selectedListing.item_id;
    } else if (selectedChallanItem) {
      sku = selectedChallanItem.item_id;
    }

    if (sku != "") {
      const obj: ImsChallanItem | undefined = challanItems.find(
        (invoiceItem) => invoiceItem.item_id == sku
      );
      if (obj) {
        setIsItemPartOfChallan(true);
        setRequiredQuantity(obj.item_quantity);
      }
    }
  }, [selectedListing, selectedChallanItem]);

  const handleRequiredQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setRequiredQuantity(parseInt(value));
  };

  const onCardClick = (currentListing: ImsListing) => {
    // console.log("card clicked: ", currentListing);

    if (
      (currentAuditChallan || currentDeliveryChallan) &&
      currentListing.available_quantity == 0
    )
      setEmptyModalShow(true);
    else {
      setSelectedListing(currentListing);
      setModalShow(true);
    }
  };

  const handleOnClickAdd = () => {
    if (requiredQuantity <= 0) {
      toast.error("Please enter a non-zero quantity to add.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }
    console.log(selectedListing);

    if (currentAuditChallan || currentDeliveryChallan) {
      if (
        selectedListing &&
        requiredQuantity > selectedListing.available_quantity
      ) {
        toast.error(
          "Challan quantity cannot be greater than existing quantity.",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
      }
    }

    addToChallan(selectedListing, selectedChallanItem, requiredQuantity);
    setModalShow(false);
  };

  const addToChallan = async (
    listing: ImsListing | null,
    challanItem: ImsChallanItem | null,
    qty: number
  ) => {
    setShowLoadingPage(true);
    if (listing || challanItem) {
      const sku: string = listing
        ? listing.item_id
        : challanItem
        ? challanItem.item_id
        : "";
      if (sku == "") return;

      //check sku in challanItems
      const obj: ImsChallanItem | undefined = _.cloneDeep(
        challanItems.find((challanItem) => challanItem.item_id == sku)
      );

      let newChallanItem: any;
      let isNewItem: boolean = true;
      if (obj) {
        obj.item_quantity = qty;
        newChallanItem = obj;
        isNewItem = false;
      } else {
        newChallanItem = {
          challan_id: challan_id,
          item_id: sku,
          item_quantity: qty,
          created_by: userDetailsFromStore.user_details.userId,
        };
        console.log(newChallanItem);
      }

      if (currentAuditChallan) {
        try {
          const response = await challanNetworkUtil.addToAuditChallan(
            newChallanItem,
            challan_id
          );
          // console.log(response.data);
          if (isNewItem) {
            setChallanItems((prevList) => [...prevList, response.data]);
          } else {
            const updatedList = challanItems.map((challanItem, index) =>
              challanItem.item_id === sku ? response.data : challanItem
            );
            setChallanItems(updatedList);
          }
          setShowLoadingPage(false);
          toast.success("Success.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          // setCurrentPurchaseOrder(response.data);
        } catch (error) {
          console.error(
            "Error in fetching vendor listings by vendor id :",
            error
          );
          setShowLoadingPage(false);
          toast.error("Error while adding, try again.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      } else if (currentDeliveryChallan) {
        try {
          const response = await challanNetworkUtil.addToDeliveryChallan(
            newChallanItem,
            challan_id
          );
          // console.log(response.data);
          if (isNewItem) {
            setChallanItems((prevList) => [...prevList, response.data]);
          } else {
            const updatedList = challanItems.map((challanItem, index) =>
              challanItem.item_id === sku ? response.data : challanItem
            );
            setChallanItems(updatedList);
          }
          setShowLoadingPage(false);
          toast.success("Success.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          // setCurrentPurchaseOrder(response.data);
        } catch (error) {
          console.error(
            "Error in fetching vendor listings by vendor id :",
            error
          );
          setShowLoadingPage(false);
          toast.error("Error while adding, try again.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      } else if (currentRecievableChallan) {
        try {
          const response = await challanNetworkUtil.addToRecievableChallan(
            newChallanItem,
            challan_id
          );
          // console.log(response.data);
          if (isNewItem) {
            setChallanItems((prevList) => [...prevList, response.data]);
          } else {
            const updatedList = challanItems.map((challanItem, index) =>
              challanItem.item_id === sku ? response.data : challanItem
            );
            setChallanItems(updatedList);
          }
          setShowLoadingPage(false);
          toast.success("Success.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          // setCurrentPurchaseOrder(response.data);
        } catch (error) {
          console.error("Error :", error);
          setShowLoadingPage(false);
          toast.error("Error while adding, try again.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      }
    }
  };

  const onChallanItemClick = (challanItem: ImsChallanItem) => {
    setModalShow(true);
    setSelectedChallanItem(challanItem);
    // setSelectedListing(invoiceItem.listing);
    setRequiredQuantity(challanItem.item_quantity);
  };

  const handleOnClickDelete = async () => {
    setModalShow(false);
    if (selectedChallanItem || selectedListing) {
      setShowLoadingPage(true);
      const sku: string = selectedChallanItem
        ? selectedChallanItem.item_id
        : selectedListing
        ? selectedListing.item_id
        : "";
      const itemToDelete = {
        challan_id: challan_id,
        item_id: sku,
      };

      if (currentAuditChallan) {
        try {
          const response = await challanNetworkUtil.deleteItemFromAuditChallan(
            itemToDelete,
            challan_id
          );

          const updatedList = challanItems.filter(
            (challanItem, index) => challanItem.item_id != sku
          );
          setChallanItems(updatedList);
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          setShowLoadingPage(false);
        } catch (error) {
          console.error("Error :", error);
          toast.error("Error while deleting, try again.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          setShowLoadingPage(false);
        }
      } else if (currentDeliveryChallan) {
        try {
          const response =
            await challanNetworkUtil.deleteItemFromDeliveryChallan(
              itemToDelete,
              challan_id
            );

          const updatedList = challanItems.filter(
            (challanItem, index) => challanItem.item_id != sku
          );
          setChallanItems(updatedList);
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          setShowLoadingPage(false);
        } catch (error) {
          console.error("Error :", error);
          toast.error("Error while deleting, try again.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          setShowLoadingPage(false);
        }
      } else if (currentRecievableChallan) {
        try {
          const response =
            await challanNetworkUtil.deleteItemFromRecievableChallan(
              itemToDelete,
              challan_id
            );

          const updatedList = challanItems.filter(
            (challanItem, index) => challanItem.item_id != sku
          );
          setChallanItems(updatedList);
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          setShowLoadingPage(false);
        } catch (error) {
          console.error("Error :", error);
          toast.error("Error while deleting, try again.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          setShowLoadingPage(false);
        }
      }
    }
  };

  const publishChallan = async () => {
    if (currentAuditChallan) {
      try {
        const response = await challanNetworkUtil.publishAuditChallan(
          challan_id
        );
        if (currentAuditChallan) {
          const updatedChallan = {
            ...currentAuditChallan,
            challan_status: "PUBLISHED",
          };
          setCurrentAuditChallan(updatedChallan);
        }
        toast.success("Published successfully", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } catch (error) {
        console.error("Error in publishing challan :", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 406) {
          const test = err.response.data as InvoiceError;
          if (test.oosSKUs && test.oosSKUs.length > 0) {
            // Display out-of-stock SKUs if they are provided in the error response
            toast.error(
              `Looks like your challan was pending for long, following skus has less inventory than booked by challan: ${test.oosSKUs.join(
                ", "
              )}`,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
              }
            );
          }
        } else {
          toast.error("Error while publishing, try again.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      }
    } else if (currentDeliveryChallan) {
      try {
        const response = await challanNetworkUtil.publishDeliveryChallan(
          challan_id
        );
        if (currentDeliveryChallan) {
          const updatedChallan = {
            ...currentDeliveryChallan,
            challan_status: "PUBLISHED",
          };
          setCurrentDeliveryChallan(updatedChallan);
        }
        toast.success("Published successfully", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } catch (error) {
        console.error("Error in publishing challan :", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 406) {
          const test = err.response.data as InvoiceError;
          if (test.oosSKUs && test.oosSKUs.length > 0) {
            // Display out-of-stock SKUs if they are provided in the error response
            toast.error(
              `Looks like your challan was pending for long, following skus has less inventory than booked by challan: ${test.oosSKUs.join(
                ", "
              )}`,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
              }
            );
          }
        } else {
          toast.error("Error while publishing, try again.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      }
    } else if (currentRecievableChallan) {
      try {
        const response = await challanNetworkUtil.publishRecievableChallan(
          challan_id
        );
        if (currentRecievableChallan) {
          const updatedChallan = {
            ...currentRecievableChallan,
            challan_status: "PUBLISHED",
          };
          setCurrentRecievableChallan(updatedChallan);
        }
        toast.success("Published successfully", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } catch (error) {
        console.error("Error in publishing challan :", error);
        const err = error as AxiosError;
        if (err.response && err.response.data && err.response.status == 406) {
          const test = err.response.data as InvoiceError;
          if (test.oosSKUs && test.oosSKUs.length > 0) {
            // Display out-of-stock SKUs if they are provided in the error response
            toast.error(
              `Looks like your challan was pending for long, following skus has less inventory than booked by challan: ${test.oosSKUs.join(
                ", "
              )}`,
              {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
              }
            );
          }
        } else {
          toast.error("Error while publishing, try again.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      }
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // console.log(requiredQuantity);
      handleOnClickAdd();
    }
  };

  const handleSearch = (query: string) => {
    setListings([]);
    setIsLoading(true);
    search(query, 1);
  };

  const search = async (query: string, pageNumber: number, count?: number) => {
    try {
      const response = await listingNetworkUtil.getListingsBySearchKeyword(
        query,
        pageNumber,
        count ? count : 100
      );

      const newListings: Array<ImsListing> = response.data["content"];
      setListings((prevList) => [...prevList, ...newListings]);
      setIsLoading(false);
    } catch (error) {
      console.log("Printing error from ListingsHome");
      console.error("Error:", error);
    }
  };

  return (
    <div className="overlay">
      <div className="vendor-details">
        <Header />
        {currentAuditChallan && (
          <div className="main-container">
            <div className="vendor-action-container">
              <div className="vendor-basics vendors-info-card add-items-heading">
                <div>Add Items to Audit Challan - [ {challan_id} ]</div>
              </div>
              {challanItems.length > 0 && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Challan Items</h4>
                    <div className="vendor-details-button-container">
                      {currentAuditChallan.challan_status == "PENDING" && (
                        <div>
                          <button
                            type="button"
                            onClick={() => setShowPublishConfirmation(true)}
                          >
                            Publish
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid-1-cells">
                    {challanItems.length != 0 && (
                      <div className="grid-2-cells">
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Items : </b>{" "}
                            {challanItems.length.toString()}
                          </div>
                        </Alert>
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Quantity :</b>{" "}
                            {challanItems.reduce(
                              (total, challanItem) =>
                                total + challanItem.item_quantity,
                              0
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}

                    {challanItems.map((challanItem, index) => {
                      return (
                        <div
                          className="po-item-card"
                          key={index}
                          onClick={() => onChallanItemClick(challanItem)}
                        >
                          <div>
                            <b>{challanItem.item_id}</b> -{" "}
                            {challanItem.item_quantity.toString()}
                          </div>
                          <div className="last-modified">
                            {DateTimeUtils.formatDateTime(
                              challanItem.updated_at
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {currentAuditChallan && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Audit Challan Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Challan status</label>
                      {currentAuditChallan.challan_status == "PENDING" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="danger">
                          {currentAuditChallan.challan_status}
                        </Badge>
                      )}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Challan Id</label>
                      <input
                        type="text"
                        name="invoice_id"
                        value={currentAuditChallan.id}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Audit Reason</label>
                      <input
                        type="text"
                        name="customer_shipping_address"
                        value={currentAuditChallan.audit_reason}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="po_created_by"
                        value={currentAuditChallan.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="po_date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentAuditChallan.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="invoice-listings-container">
              <div className="vendor-listings-search-bar-container ml-4">
                <ChallanSearchBarAddItem
                  onSearch={handleSearch}
                ></ChallanSearchBarAddItem>
              </div>

              {isLoading ? (
                <div className="invoice-skus-spinner-container">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div className="listings-container">
                  {listings.length != 0 && (
                    <div className="all-listings-container">
                      <ChallanEmbeddedListingsView
                        listings={listings}
                        onCardClick={onCardClick}
                      />
                    </div>
                  )}
                  {listings.length == 0 && (
                    <div className="no-items-view">Nothing to Display</div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {/* ========DELIVERY CHALLAN========= */}
        {currentDeliveryChallan && (
          <div className="main-container">
            <div className="vendor-action-container">
              <div className="vendor-basics vendors-info-card add-items-heading">
                <div>Add Items to Delivery Challan - [ {challan_id} ]</div>
              </div>
              {challanItems.length > 0 && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Challan Items</h4>
                    <div className="vendor-details-button-container">
                      {currentDeliveryChallan.challan_status == "PENDING" && (
                        <div>
                          <button
                            type="button"
                            onClick={() => setShowPublishConfirmation(true)}
                          >
                            Publish
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid-1-cells">
                    {challanItems.length != 0 && (
                      <div className="grid-2-cells">
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Items : </b>{" "}
                            {challanItems.length.toString()}
                          </div>
                        </Alert>
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Quantity :</b>{" "}
                            {challanItems.reduce(
                              (total, challanItem) =>
                                total + challanItem.item_quantity,
                              0
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}

                    {challanItems.map((challanItem, index) => {
                      return (
                        <div
                          className="po-item-card"
                          key={index}
                          onClick={() => onChallanItemClick(challanItem)}
                        >
                          <div>
                            <b>{challanItem.item_id}</b> -{" "}
                            {challanItem.item_quantity.toString()}
                          </div>
                          <div className="last-modified">
                            {DateTimeUtils.formatDateTime(
                              challanItem.updated_at
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {currentDeliveryChallan && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Delivery Challan Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Challan status</label>
                      {currentDeliveryChallan.challan_status == "PENDING" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="danger">
                          {currentDeliveryChallan.challan_status}
                        </Badge>
                      )}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Challan Id</label>
                      <input
                        type="text"
                        name="invoice_id"
                        value={currentDeliveryChallan.id}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Buyer Name</label>
                      <input
                        type="text"
                        name="buyer_name"
                        value={currentDeliveryChallan.buyer_name}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Buyer Contact Number</label>
                      <input
                        type="text"
                        name="buyer_contact_number"
                        value={currentDeliveryChallan.buyer_contact_number}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Buyer Billing Address</label>
                      <input
                        type="text"
                        name="buyer_billing_address"
                        value={currentDeliveryChallan.buyer_billing_address}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Buyer Shipping Address</label>
                      <input
                        type="text"
                        name="buyer_shipping_address"
                        value={currentDeliveryChallan.buyer_shipping_address}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Buyer GSTIN</label>
                      <input
                        type="text"
                        name="buyer_gstin"
                        value={currentDeliveryChallan.buyer_gstin}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Challan Date</label>
                      <input
                        type="text"
                        name="challan_date"
                        value={DateTimeUtils.formatDateOnly(
                          currentDeliveryChallan.challan_date
                        )}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="po_created_by"
                        value={currentDeliveryChallan.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="po_date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentDeliveryChallan.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="invoice-listings-container">
              <div className="vendor-listings-search-bar-container ml-4">
                <ChallanSearchBarAddItem
                  onSearch={handleSearch}
                ></ChallanSearchBarAddItem>
              </div>

              {isLoading ? (
                <div className="invoice-skus-spinner-container">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div className="listings-container">
                  {listings.length != 0 && (
                    <div className="all-listings-container">
                      <ChallanEmbeddedListingsView
                        listings={listings}
                        onCardClick={onCardClick}
                      />
                    </div>
                  )}
                  {listings.length == 0 && (
                    <div className="no-items-view">Nothing to Display</div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {/* ========RECIEVABLE CHALLAN========= */}
        {currentRecievableChallan && (
          <div className="main-container">
            <div className="vendor-action-container">
              <div className="vendor-basics vendors-info-card add-items-heading">
                <div>Map items to Recievable Challan - [ {challan_id} ]</div>
              </div>
              {challanItems.length > 0 && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Challan Items</h4>
                    <div className="vendor-details-button-container">
                      {currentRecievableChallan.challan_status == "PENDING" && (
                        <div>
                          <button
                            type="button"
                            onClick={() => setShowPublishConfirmation(true)}
                          >
                            Publish
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid-1-cells">
                    {challanItems.length != 0 && (
                      <div className="grid-2-cells">
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Items : </b>{" "}
                            {challanItems.length.toString()}
                          </div>
                        </Alert>
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total Quantity :</b>{" "}
                            {challanItems.reduce(
                              (total, challanItem) =>
                                total + challanItem.item_quantity,
                              0
                            )}
                          </div>
                        </Alert>
                      </div>
                    )}

                    {challanItems.map((challanItem, index) => {
                      return (
                        <div
                          className="po-item-card"
                          key={index}
                          onClick={() => onChallanItemClick(challanItem)}
                        >
                          <div>
                            <b>{challanItem.item_id}</b> -{" "}
                            {challanItem.item_quantity.toString()}
                          </div>
                          <div className="last-modified">
                            {DateTimeUtils.formatDateTime(
                              challanItem.updated_at
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {currentRecievableChallan && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Recievable Challan Images</h4>
                  </div>
                  <div className="image-list-add-new-item">
                    <div className={`image-placeholder-add-item`}>
                      <img src={currentRecievableChallan.img_link1} />
                    </div>

                    <div className={`image-placeholder-add-item`}>
                      <img src={currentRecievableChallan.img_link2} />
                    </div>
                  </div>
                </div>
              )}

              {currentRecievableChallan && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Recievable Challan Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Challan status</label>
                      {currentRecievableChallan.challan_status == "PENDING" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="danger">
                          {currentRecievableChallan.challan_status}
                        </Badge>
                      )}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Challan Id</label>
                      <input
                        type="text"
                        name="invoice_id"
                        value={currentRecievableChallan.id}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Vendor Challan Number</label>
                      <input
                        type="text"
                        name="invoice_id"
                        value={currentRecievableChallan.vendor_challan_number}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Vendor Name</label>
                      <input
                        type="text"
                        name="buyer_name"
                        value={currentRecievableChallan.vendor_name}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Contact Number</label>
                      <input
                        type="text"
                        name="buyer_contact_number"
                        value={currentRecievableChallan.vendor_contact_number}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Vendor Address</label>
                      <input
                        type="text"
                        name="buyer_billing_address"
                        value={currentRecievableChallan.vendor_address}
                        disabled={true}
                      />
                    </div>

                    <div className="grid-item-details-card">
                      <label>Vendor GSTIN</label>
                      <input
                        type="text"
                        name="buyer_gstin"
                        value={currentRecievableChallan.vendor_gstin}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Challan Date</label>
                      <input
                        type="text"
                        name="challan_date"
                        value={DateTimeUtils.formatDateOnly(
                          currentRecievableChallan.challan_date
                        )}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="po_created_by"
                        value={currentRecievableChallan.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="po_date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentRecievableChallan.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="invoice-listings-container">
              <div className="vendor-listings-search-bar-container ml-4">
                <ChallanSearchBarAddItem
                  onSearch={handleSearch}
                ></ChallanSearchBarAddItem>
              </div>

              {isLoading ? (
                <div className="invoice-skus-spinner-container">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div className="listings-container">
                  {listings.length != 0 && (
                    <div className="all-listings-container">
                      <ChallanEmbeddedListingsView
                        listings={listings}
                        onCardClick={onCardClick}
                      />
                    </div>
                  )}
                  {listings.length == 0 && (
                    <div className="no-items-view">Nothing to Display</div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <Modal
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={() => setModalShow(false)}
      >
        {/* {selectedListing && selectedListing.available_quantity > 0 ? ( */}
        <Modal.Body>
          <div className="required-quantity-final-price-container">
            <div className="invoice-quantity-container">
              <div>
                <div>Item Count</div>
              </div>
              <input
                type="number"
                name="order_quantity"
                value={requiredQuantity.toString()}
                onChange={(e) => handleRequiredQuantityChange(e)}
                onKeyPress={handleKeyPress}
                ref={inputRef}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="add-item-quantity-card-button-container">
            <Button className="mr-2" onClick={() => setModalShow(false)}>
              Cancel
            </Button>
            <Button onClick={handleOnClickAdd}>Add</Button>
            {isItemPartOfChallan && (
              <Button className="ml-2" onClick={handleOnClickDelete}>
                Delete
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={emptyModalShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setEmptyModalShow(false)}
      >
        <Modal.Body>
          <div className="required-quantity-final-price-container">
            Unable to add to challan ! Selected Product has 0 available
            inventory.
          </div>
        </Modal.Body>
      </Modal>

      {showLoadingPage && (
        <div className="loading-page-horizontal">
          <Spinner animation="grow" className="mr-2" />
          <Spinner animation="grow" className="mr-2" />
          <Spinner animation="grow" className="mr-2" />
        </div>
      )}

      <Modal
        show={showPublishConfirmation}
        onHide={() => setShowPublishConfirmation(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Publish Challan ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentRecievableChallan
            ? "Are you sure you want to publish this challan ? this will lead to inventory addition."
            : "Are you sure you want to publish this challan ? this will lead to inventory subtraction."}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowPublishConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={publishChallan}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ChallanAddItems;
